.banner-g--nosotros .banner-g__image {
	height:780px
}
.banner-g--nosotros .banner-g__info {
	width:40%;
	margin-left:0;
	margin-right:auto
}
.banner-g--nosotros .title-g .title-g__title h1, .banner-g--nosotros .title-g .title-g__title h2, .banner-g--nosotros .title-g .title-g__title h3, .banner-g--nosotros .title-g .title-g__title h4, .banner-g--nosotros .title-g .title-g__title h5 {
	letter-spacing:5.4px
}
.banner-g--nosotros .title-g .title-g__text {
	margin-top:35px;
	line-height:1.5
}
@media (max-width:1200px) {
	.banner-g--nosotros .banner-g__info {
		width:50%
	}
}
@media (max-width:1024px) {
	.banner-g--nosotros .banner-g__image {
		height:610px;
		padding:70px 0;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
	.banner-g--nosotros .banner-g__info {
		width:100%
	}
	.banner-g--nosotros .banner-g__inside {
		-webkit-box-align:start;
		-ms-flex-align:start;
		align-items:flex-start
	}
}
.block-text--nosotros .block-text__title {
	max-width:785px;
	width:100%;
	margin:auto
}
.block-text--nosotros .block-text__title .title-g__title h1, .block-text--nosotros .block-text__title .title-g__title h2, .block-text--nosotros .block-text__title .title-g__title h3, .block-text--nosotros .block-text__title .title-g__title h4, .block-text--nosotros .block-text__title .title-g__title h5 {
	letter-spacing:1.63px
}
.block-text--nosotros .block-text__title .title-g__text {
	line-height:1.5
}
.nosotros--zigzag .zigzap-g__title .title-g__icon {
	width:112px;
	margin-bottom:20px
}
.nosotros--zigzag .zigzap-g__title .title-g__icon img {
	margin-left:0;
	margin-right:auto;
	height:auto
}
.nosotros--zigzag .zigzap-g__title .title-g__title {
	max-width:370px;
	width:100%;
	margin-left:0;
	margin-right:auto
}
.nosotros--zigzag .zigzap-g__title .title-g__text {
	line-height:1.7;
	margin-top:50px
}
@media (max-width:1024px) {
	.nosotros--zigzag .zigzap-g__title .title-g__icon {
		width:65px
	}
}
.block-g--nosotros {
	padding:100px 0 120px 0;
	background:#F1F1F1
}
.aboutus-clients {
	padding:100px 0 100px 0;
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%)
}
.aboutus-clients__imgs {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	-webkit-box-pack:center;
	-ms-flex-pack:center;
	justify-content:center
}
.aboutus-clients__imgs figure {
	margin:20px
}
.aboutus-clients__imgs figure img {
	max-width:100%;
	margin:0;
	height:auto
}
@media (max-width:1024px) {
	.aboutus-clients {
		padding:70px 0 70px 0
	}
}
@media (max-width:1200px) {
	.block-g--nosotros {
		padding:80px 0 80px 0
	}
}
@media (max-width:1024px) {
	.block-g--nosotros {
		padding:60px 0 60px 0
    }
}
.block-text {
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
	padding:120px 0;
	text-align:center
}
.block-text__container {
	max-width:870px
}
.block-text__title .title-g__text {
	margin-top:50px;
	line-height:1.3
}
