.footer-message {
	padding:90px 0
}
.footer-message__title {
	text-align:center
}
.footer-message__title .title-g__text {
	margin-top:30px;
	line-height:1.5
}
.footer-message__title .title-g__button {
	margin-top:40px
}
@media (max-width:1024px) {
	.footer-message {
		padding:70px 0
	}
	.footer-message__title .title-g__text {
		display:none
	}
}
.footer-message--descargas {
	padding-top:0;
	padding-bottom:70px
}
.footer-message--descargas .footer-message__title {
	padding-top:70px;
	border-top:1px solid #ddd
}
@media (max-width:1024px) {
	.footer-message--descargas {
		display:none
	}
}
.footer-message--tutoriales {
	padding-top:0;
	padding-bottom:70px
}
.footer-message--tutoriales .footer-message__title {
	padding-top:70px;
	border-top:1px solid #ddd
}
@media (max-width:1024px) {
	.footer-message--tutoriales {
		display:none
	}
}
