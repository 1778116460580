.stylesAll-content {
	color:#000;
	line-height:1.4;
	font-size:19px
}
.stylesAll-content a {
	color:#000
}
.stylesAll-content strong {
	font-family:"TTNorms-Bold", sans-serif
}
.stylesAll-content h1, .stylesAll-content h2, .stylesAll-content h3, .stylesAll-content h4, .stylesAll-content h5, .stylesAll-content h6 {
	line-height:1.25;
	margin:0 0 20px 0
}
.stylesAll-content li > ul, .stylesAll-content li > ol {
	padding:15px 0 15px 25px
}
.stylesAll-content li > ul:last-child, .stylesAll-content li > ol:last-child {
	padding-bottom:0
}
.stylesAll-content ul {
	padding-left:25px
}
.stylesAll-content ul li {
	margin-bottom:8px
}
.stylesAll-content ol {
	padding-left:25px
}
.stylesAll-content ol li {
	margin-bottom:19px
}
.stylesAll-content iframe {
	width:100%;
	max-width:560px
}
.stylesAll-content img {
	max-width:100%;
	height:auto
}
.stylesAll-content blockquote {
	background:#efefef;
	padding:20px;
	margin:10px 0
}
.stylesAll-content blockquote p:last-child {
	margin:0
}
@media screen and (max-width:1024px) {
	.stylesAll-content {
		font-size:14px
	}
}

.news-block {
	padding-bottom:70px;
	padding-top:30px
}
.news-block__miga {
	padding-bottom:25px
}
.news-block__miga .miga-g > ul {
	-webkit-box-pack:end;
	-ms-flex-pack:end;
	justify-content:flex-end
}
.news-titleall {
	padding-bottom:55px
}
.news-listPage__itemTitle > h3 {
	font-size:36px;
	color:#E3051B;
	font-family:"TTNorms-Light", sans-serif;
	margin:0;
	line-height:1.3
}
.news-listPage__itemImage {
	overflow:hidden
}
.news-listPage__itemImage img {
	-webkit-transition:all .3s ease-in-out;
	-o-transition:all .3s ease-in-out;
	transition:all .3s ease-in-out
}
.news-listPage__itemImage:hover img {
	-webkit-transform:scale(1.1);
	-ms-transform:scale(1.1);
	transform:scale(1.1)
}
.news-listPage__itemInfo {
	padding:40px 60px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	background:#F1F1F1
}
.news-listPage__itemText {
	font-size:15px;
	color:#000;
	margin-top:30px
}
.news-listPage__itemIn {
	max-width:670px;
	width:100%;
	margin-right:auto;
	margin-left:0
}
.news-listPage__itemCatDate {
	margin-top:30px
}
.news-listPage__itemCatDate > h4 {
	font-size:15px;
	color:#000;
	font-family:"TTNorms-Light", sans-serif;
	margin:0
}
.news-listPage__itemCatDate > p {
	font-size:15px;
	color:#E3051B;
	font-family:"TTNorms-Light", sans-serif;
	margin:0
}
.news-listPageRecent {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap;
	padding-top:70px;
	border-top:1px solid #E6E6E6
}
.news-listPage {
	width:calc(100% - 500px);
	padding-right:40px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
.news-SearchrecentCategories {
	width:500px
}
.news-title {
	margin-bottom:30px;
	max-width:400px
}
.news-title > h2 {
	font-size:30px;
	font-family:"TTNorms-Light", sans-serif;
	color:#000;
	margin:0;
	line-height:1.3
}
.news-aside__el {
	margin-bottom:35px
}
.news-aside__el:last-child {
	margin-bottom:0
}
.news-aside__item {
	background:#F1F1F1;
	padding:25px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	display:block;
	margin-bottom:30px
}
.news-aside__item:last-child {
	margin-bottom:0
}
.news-aside__item > h3 {
	font-size:15px;
	font-family:"TTNorms-Light", sans-serif;
	color:#000;
	line-height:1.3
}
.news-aside__item > h4 {
	font-size:15px;
	font-family:"TTNorms-Light", sans-serif;
	color:#E3051B;
	margin:0
}
.blog-block--suscribe {
	padding-bottom:80px
}
.form-suscribeblog__form form {
	margin:0 !important
}
@media (max-width:1024px) {
	.news-listPage {
		width:calc(100% - 320px);
		padding-right:40px;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
	.news-SearchrecentCategories {
		width:320px
	}
	.news-listPageRecent {
		padding-top:40px
	}
	.news-titleall {
		padding-bottom:30px
	}
	.news-title > h2 {
		font-size:24px
	}
	.news-listPage__itemTitle > h3 {
		font-size:30px
	}
	.news-listPage__itemInfo {
		padding:40px
	}
}
@media (max-width:1024px) {
	.news-SearchrecentCategories {
		width:100%;
		padding-top:40px
	}
	.news-listPage {
		width:100%;
		padding-right:0
	}
	.news-listPage__itemTitle > h3 {
		font-size:14px
	}
	.news-listPage__itemCatDate {
		margin-top:30px
	}
	.news-listPage__itemCatDate > h4 {
		font-size:14px
	}
	.news-listPage__itemCatDate > p {
		font-size:14px
	}
	.news-block__container {
		padding:0 !important
	}
	.news-listPage__itemInfo {
		padding:40px
	}
	.news-block__miga {
		display:none
	}
	.news-titleall {
		padding-left:40px;
		padding-right:40px;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
	.news-aside__item {
		padding:25px 40px
	}
	.news-title {
		max-width:100%;
		padding:0 40px;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
	.news-title > h2 {
		font-size:23px
	}
	.news-block {
		padding-bottom:35px
	}
}
@media (max-width:1024px) {
	.news-list {
		display:-webkit-box;
		display:-ms-flexbox;
		display:flex;
		-ms-flex-wrap:wrap;
		flex-wrap:wrap;
		-webkit-box-pack:start;
		-ms-flex-pack:start;
		justify-content:flex-start
	}
	.news-list__item {
		-ms-flex-wrap:wrap;
		flex-wrap:wrap;
		margin:0;
		margin-bottom:45px;
		width:48%;
		margin-right:4%
	}
	.news-list__item:nth-child(2n+2) {
		margin-right:0
	}
	.news-list__item:last-child {
		margin-bottom:0
	}
	.news-list__image {
		width:100%;
		height:auto;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
	.news-list__imageIn {
		padding-bottom:60%
	}
	.news-list__content {
		width:100%;
		padding-left:0;
		margin-top:15px
	}
}
@media (max-width:400px) {
	.news-listPage__itemInfo {
		padding:30px
	}
	.news-titleall {
		padding-left:30px;
		padding-right:30px
	}
	.news-aside__item {
		padding:25px 30px
	}
	.news-title {
		padding:0 30px
	}
}
.newsDetail-block {
	padding-top:0;
	padding-bottom:60px
}
.news-detail__categories {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	margin-left:20px
}
.news-detail__categories > a {
	font-size:15px;
	color:#000;
	display:inline-block;
	margin-right:10px
}
.news-detail__categories > a:last-child {
	margin-right:0
}
.news-image {
	text-align:center;
	margin-top:25px
}
.news-image img {
	margin:auto;
	width:100%;
	height:auto
}
.newsDetail-info {
	margin-top:30px
}
.newsDetail-dateCategories {
	margin-top:30px
}
.newsDetail-dateCategories h4 {
	font-size:18px;
	color:#000;
	margin:0
}
.newsDetail-dateCategories p {
	font-size:18px;
	color:#E3051B
}
.news-content {
	margin-top:30px
}
.newsDetail-content__title > h1 {
	font-size:36px;
	font-family:"TTNorms-Light", sans-serif;
	color:#E3051B;
	line-height:1.3;
	margin:0;
	letter-spacing:2.7px
}
.newsDetail-rel {
	padding:75px 0 60px 0;
	background:#F1F1F1
}
.newsDetail-rel .item-g4__info {
	background:#fff
}
.newsDetail__items {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap
}
.newsDetail__item {
	width:32%;
	margin:0;
	margin-right:2%;
	margin-bottom:2%
}
.newsDetail__item:nth-child(3n+3) {
	margin-right:0
}
.newsDetail-rel__title {
	margin-bottom:75px
}
.newsDetail-block__container {
	max-width:980px
}
.newsDetail-miga {
	margin-top:27px;
	margin-bottom:30px
}
.newsDetail-miga .miga-g > ul {
	-webkit-box-pack:end;
	-ms-flex-pack:end;
	justify-content:flex-end
}
@media (max-width:1024px) {
	.newsDetail-block {
		padding-top:60px;
		padding-bottom:60px
	}
	.newsDetail-miga {
		display:none
	}
	.newsDetail-rel__title {
		margin-bottom:50px
	}
	.newsDetail-content__title > h1 {
		font-size:30px
	}
	.newsDetail-rel {
		padding-top:60px;
		padding-bottom:60px
	}
	.newsDetail__item {
		width:48%;
		margin-right:4% !important;
		margin-bottom:4% !important
	}
	.newsDetail__item:nth-child(2n+2) {
		margin-right:0 !important
	}
	.newsDetail-info {
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
}
@media (max-width:1024px) {
	.newsDetail-block__container {
		padding:0 !important
	}
	.newsDetail-rel__container {
		padding:0 !important
	}
	.newsDetail-block {
		padding-top:40px;
		padding-bottom:40px
	}
	.newsDetail-rel {
		padding:50px 0 35px 0
	}
	.blog-block__filter {
		padding:0 40px;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
	.newsDetail-rel__title {
		margin-bottom:40px
	}
	.newsDetail-content__title > h1 {
		font-size:16px
	}
	.newsDetail-dateCategories h4 {
		font-size:13px
	}
	.newsDetail-dateCategories p {
		font-size:13px
	}
	.newsDetail-rel__title {
		padding:0 40px;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
	.newsDetail-info {
		padding:0 40px
	}
}
@media (max-width:480px) {
	.newsDetail__item {
		width:100%;
		margin-right:0 !important;
		margin-bottom:40px !important
	}
	.newsDetail__item:nth-child(2n+2) {
		margin-right:0 !important
	}
	.newsDetail__item:last-child {
		margin-bottom:0 !important
	}
	.blog-block__filter {
		padding:0 30px
    }
}
@media (max-width:400px) {
	.newsDetail-info {
		padding:0 30px
	}
	.newsDetail-rel__title {
		padding:0 30px
	}
}
.blog-detail--suscribe {
	background:#F1F1F1;
	padding-bottom:75px
}
.blog--page {
	padding:70px 0
}
.blog--page .blog-block__filterCategoria {
	width:316px;
	margin-right:35px
}
.blog--page .blog-block__filterAnio, .blog--page .blog-block__filterMes {
	width:85px
}
.blog--page .blog-block__filterMes {
	margin-right:35px
}
.blog--page .blog-block__filterOrdenar {
	width:195px
}
@media (max-width:1024px) {
	.blog-block__filter {
		-ms-flex-wrap:wrap;
		flex-wrap:wrap
	}
	.blog-block__filter1 {
		-ms-flex-wrap:wrap;
		flex-wrap:wrap;
		width:100%
	}
	.blog--page .blog-block__filterCategoria {
		margin-right:0;
		width:100%
	}
	.blog--page .blog-block__filterAnio, .blog--page .blog-block__filterMes {
		width:48%
	}
	.blog--page .blog-block__filterMes {
		margin-right:4%
	}
	.blog-block__filter2 {
		width:48%
	}
	.blog--page .blog-block__filterOrdenar {
		width:100%
	}
	.blog--page .blog-block__filterCategoria {
		margin-bottom:20px
	}
	.blog--page .blog-block__filterOrdenar {
		margin-top:20px
	}
}
@media (max-width:1024px) {
	.blog--page {
		padding:40px 0
	}
}
.blog-block__filter {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-pack:justify;
	-ms-flex-pack:justify;
	justify-content:space-between;
	margin-bottom:40px
}
.blog-block__filter1 {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center
}
.blog-block__items {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap
}
.blog-block__item {
	width:32%;
	margin:0;
	margin-right:2%;
	margin-bottom:2%
}
.blog-block__item:nth-child(3n+3) {
	margin-right:0
}
@media (max-width:1024px) {
	.blog-block__item {
		width:48%;
		margin-right:4% !important;
		margin-bottom:4% !important
	}
	.blog-block__item:nth-child(2n+2) {
		margin-right:0 !important
	}
}
@media (max-width:1024px) {
	.blog-block__container {
		padding:0 !important
	}
	.blog-block__filter {
		padding:0 40px;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
}
@media (max-width:480px) {
	.blog-block__filter {
		padding:0 30px
	}
	.blog-block__item {
		width:100%;
		margin-right:0 !important;
		margin-bottom:40px !important
	}
	.blog-block__item:nth-child(2n+2) {
		margin-right:0 !important
	}
	.blog-block__item:last-child {
		margin-bottom:0 !important
	}
}
.form-suscribeblog {
	margin:0
}
.form-suscribeblog .wpcf7 .wpcf7-response-output.wpcf7-validation-errors {
	color:#fff !important;
	border:1px solid #fff
}
.form-suscribeblog .wpcf7 .wpcf7-not-valid-tip {
	color:#fff !important
}
.form-suscribeblog .button-g .ajax-loader {
	color:#fff !important
}
.form-suscribeblog__block {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	-webkit-box-pack:justify;
	-ms-flex-pack:justify;
	justify-content:space-between;
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
	padding:75px 70px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
.form-suscribeblog__title {
	width:35%
}
.form-suscribeblog__form {
	width:50%
}
@media (max-width:1200px) {
	.form-suscribeblog__block {
		padding:50px
	}
	.form-suscribeblog__title {
		width:45%
	}
	.form-suscribeblog__form {
		width:50%
	}
}
@media (max-width:1024px) {
	.form-suscribeblog__block {
		padding:50px 30px
	}
}
@media (max-width:1024px) {
	.form-suscribeblog__container {
		padding:0 !important
	}
	.form-suscribeblog__block {
		-ms-flex-wrap:wrap;
		flex-wrap:wrap;
		padding:50px 40px
	}
	.form-suscribeblog__block .form-block__text {
		display:none
	}
	.form-suscribeblog__title {
		width:100%;
		margin-bottom:30px
	}
	.form-suscribeblog__form {
		width:100%
	}
}
@media (max-width:400px) {
	.form-suscribeblog__block {
		padding:40px 30px
	}
}
.newsDetail-rel h1 {
    letter-spacing:1px;
}
.newsDetail-content ul {
    margin-left:0;
}
.blog-block .wpcf7{
    height:100px;
}
.blog-block .button-g:hover {
    transform:none;
    box-shadow:none;
}
.blog-block .button-g .ajax-loader {
    top:0;
}
/*.news-aside__item > h3 {
    margin-bottom:0;
}*/
@media (max-width:767px) {
    .blog-block .block-g__button.center {
        margin-top: 40px;
    }
    .newsDetail-content.stylesAll-content {
        line-height:1.6;
    }
    .news-listPage__itemText {
        font-size:14px;
    }
}
/* Tablet */
@media (min-width:768px) and (max-width:1024px) {
    .news-listPage__item img  {
        width:100%;
    }
    .blog-block__filter,
    .news-title {
        padding:0;
    }
    .news-listPage__itemCatDate>h4 {
        font-family: "TTNorms-Regular", sans-serif;
    }
    .newsDetail-dateCategories h4,
    .newsDetail-dateCategories p {
        font-size: 14px;
        font-family: "TTNorms-Regular", sans-serif;
    }
    .newsDetail-content__title>h1 {
        letter-spacing: normal;
    }
    .news-titleall {
        padding: 30px 0;
    }
    .news-listPage__itemTitle > h3,
    .filter-block__result .title-g__title h2,
    .news-listPage__itemCatDate > p,
    .news-listPage__itemTitle > h3 {
        font-family: "TTNorms-Regular", sans-serif;
    }
    .news-aside__item > h3,
    .news-listPage__itemTitle>h3 {
        letter-spacing: 1.2px!important;
    }
    .newsDetail-listPage > figure > img {
        margin:0;
    }
}
/* Desktop and up */
@media (min-width:1025px) {
    .news-listPage__itemCatDate > h4, .item-g4__text span {
        font-family:"TTNorms-Regular", sans-serif;
    }
    .news-listPage__itemCatDate > p, .news-aside__item > h4 {
        font-family:"TTNorms-Light", sans-serif;
        font-weight:600;
    }
}
/* Desktop */
@media (min-width:1200px) and (max-width:1600px)  {
    .news-listPage__itemTitle > h3 {
        font-size:30px;
    }
    .news-title > h2 {
        font-size:22px;
    }
    .newsDetail-content__title > h1 {
        font-size:28px;
    }
    .newsDetail-dateCategories h4 {
        font-size:15px;
    }
    .newsDetail-dateCategories p {
        font-size:15px;
    }
    .news-listPage__itemCatDate > h4, .item-g4__text span {
        font-size:13px;
    }
    .news-listPage__itemCatDate > p, .news-aside__item > h4 {
        font-size:13px;
    }
}
