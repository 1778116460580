#distribuidor_map {
	width:100%;
	height:100%
}
.distribuidorsearch-postsItem {
	display:none
}
.distribuidorsearch-postsItem.active {
	display:block
}
.banner-g--distribuidores .banner-g__image {
	height:780px
}
.banner-g--distribuidoresPortal {
	overflow:hidden
}
.banner-g--distribuidoresPortal:hover .banner-g__image {
	-webkit-transform:scale(1.1);
	-ms-transform:scale(1.1);
	transform:scale(1.1)
}
.banner-g--distribuidoresPortal .banner-g__image {
	height:507px;
	-webkit-transition:all .3s ease-in-out;
	-o-transition:all .3s ease-in-out;
	transition:all .3s ease-in-out
}
@media (max-width:1024px) {
	.banner-g--distribuidores .banner-g__image {
		height:610px
	}
	.banner-g--distribuidoresPortal .banner-g__inside {
		-webkit-box-align:start;
		-ms-flex-align:start;
		align-items:flex-start
	}
	.banner-g--distribuidoresPortal .banner-g__info {
		width:100%;
		padding-top:40px
	}
}
.banner-g--distribuidoresconv .banner-g__image {
	height:800px
}
.banner-g--distribuidoresconv .banner-g__info {
	width:43%;
	margin-right:0;
	margin-left:auto
}
.banner-g--distribuidoresconv .banner-g__info .title-g__text {
	margin-top:30px;
	line-height:1.5;
	max-width:80%
}
@media (max-width:1024px) {
	.banner-g--distribuidoresconv .banner-g__info {
		width:50%
	}
}
@media (max-width:1024px) {
	.banner-g--distribuidoresconv .banner-g__image {
		height:610px
	}
	.banner-g--distribuidoresconv .banner-g__info .title-g__text {
		max-width:100%
	}
	.banner-g--distribuidoresconv .banner-g__inside {
		-webkit-box-align:start;
		-ms-flex-align:start;
		align-items:flex-start
	}
	.banner-g--distribuidoresconv .banner-g__info {
		width:100%;
		padding-top:40px
	}
}
.block-g--distribuidores {
	padding-top:60px;
	padding-bottom:140px
}
.block-g--distribuidores .block-g__button {
	margin-top:115px;
	text-align:center
}
.block-g--distribuidores .block-g__image {
	width:100px
}
.block-g--distribuidores .block-g__title2 > h3 {
	letter-spacing:1.5px;
	line-height:1.5
}
.block-g--distribuidores .title-g__title h1, .block-g--distribuidores .title-g__title h2, .block-g--distribuidores .title-g__title h3, .block-g--distribuidores .title-g__title h4, .block-g--distribuidores .title-g__title h5 {
	line-height:1.25
}
@media (max-width:1024px) {
	.block-g--distribuidores {
		padding-top:70px;
		padding-bottom:70px
	}
	.block-g--distribuidores .block-g__button {
		margin-top:60px
	}
}
.distribuidores-form {
	background:#F1F1F1
}
.distribuidores-form form {
	margin:0
}
.distribuidores-form .input-g__title {
	text-transform:uppercase
}
.distribuidores-form .form-block__nav {
	padding-top:40px;
	padding-bottom:55px;
	border-bottom:1px solid #ccc
}
.distribuidores-form .form-block__navTitle {
	padding-top:40px;
	padding-bottom:40px
}
.distribuidores-form .form-block__b {
	padding-bottom:70px
}
.distribuidorsearch {
	position:relative
}
.distribuidorsearch__map {
	height:780px;
	background-color:#ccc;
	position:relative
}
.distribuidorsearch-content {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	pointer-events:none
}
.distribuidorsearch-search {
	width:450px;
	padding-top:45px;
	background-color:#fff;
	pointer-events:auto;
	height:780px;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-ms-flex-direction:column;
	flex-direction:column;
	-webkit-box-pack:justify;
	-ms-flex-pack:justify;
	justify-content:space-between
}
.distribuidorsearch-title {
	margin-bottom:45px;
	padding-left:50px;
	padding-right:50px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
.distribuidorsearch-imgs {
	position:absolute;
	top:25px;
	right:25px;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center
}
.distribuidorsearch-imgs > figure {
	margin:0;
	width:20px;
	margin-right:10px
}
.distribuidorsearch-imgs > figure:last-child {
	margin-right:0
}
.distribuidorsearch-imgs > figure > img {
	max-width:100%;
	height:auto;
	display:block
}
.distribuidorsearch-postsInfo {
	font-size:14px;
	color:#000;
	font-family:"TTNorms-Regular", sans-serif
}
.distribuidorsearch-postsInfo p {
	margin:0;
	line-height:1.3
}
.distribuidorsearch-form {
	margin-bottom:30px;
	padding-left:50px;
	padding-right:50px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
.distribuidorsearch-city .input-g__ico {
	right:0;
	width:40px;
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
	color:#fff;
	height:35px;
	line-height:35px;
	border-radius:5px !important;
	font-size:14px
}
.distribuidorsearch-city .input-g__input {
	padding-right:50px !important;
	height:35px !important;
	line-height:35px !important
}
.distribuidorsearch-city {
	margin-bottom:15px
}
.distribuidorsearch-cat .input-g__input {
	padding-right:45px !important;
	height:35px !important;
	line-height:35px !important
}
.distribuidorsearch-posts {
	height:400px;
	overflow:auto;
	background:#F1F1F1;
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
.distribuidorsearch-postsItem {
	padding:25px 50px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	-webkit-transition:all 0.3s ease-in-out;
	-o-transition:all 0.3s ease-in-out;
	transition:all 0.3s ease-in-out;
	cursor:pointer;
	position:relative
}
.distribuidorsearch-postsItem:hover {
	background:#c0c0c06b
}
.distribuidorsearch-postsTitle > h3 {
	color:#E3051B;
	font-family:"TTNorms-Bold", sans-serif;
	font-size:20px;
	margin-bottom:9px
}
.distribuidorsearch-postsTitle > h4 {
	color:#000;
	font-size:14px;
	font-family:"TTNorms-Bold", sans-serif
}
.distribuidorsearch-postsContent {
	margin-top:20px;
	color:#000;
	font-size:14px;
	font-family:"TTNorms-Regular", sans-serif
}
.distribuidorsearch-postsContent p {
	margin-bottom:5px
}
.distribuidorsearch-postsContent strong {
	font-family:"TTNorms-Bold", sans-serif
}
@media (max-width:1024px) {
	.distribuidorsearch-container {
		padding:0;
		display:-webkit-box;
		display:-ms-flexbox;
		display:flex;
		-webkit-box-pack:end;
		-ms-flex-pack:end;
		justify-content:flex-end
	}
	.distribuidorsearchIn-container {
		padding:0
	}
	.distribuidorsearch-search {
		width:300px
	}
	.distribuidorsearch-postsItem {
		padding-left:30px;
		padding-right:30px
	}
	.distribuidorsearch-title {
		padding-left:30px;
		padding-right:30px
	}
	.distribuidorsearch-form {
		padding-left:30px;
		padding-right:30px
	}
	.distribuidorsearch__map {
		width:calc(100% - 300px)
	}
}
@media (max-width:1024px) {
	.distribuidorsearch__map {
		height:375px;
		width:100%;
		-webkit-box-ordinal-group:3;
		-ms-flex-order:2;
		order:2
	}
	.distribuidorsearch-content {
		position:static;
		height:auto;
		-webkit-box-ordinal-group:2;
		-ms-flex-order:1;
		order:1
	}
	.distribuidorsearch-container {
		-ms-flex-wrap:wrap;
		flex-wrap:wrap
	}
	.distribuidorsearch-search {
		height:auto
	}
	.distribuidorsearch-search {
		width:100%
	}
	.distribuidorsearch-posts {
		height:300px
	}
}
.distribuidor--distribuidor .block-distribuidor__formbtn2 {
	opacity:0;
	visibility:hidden;
	pointer-events:none
}
.map-marker-popup {
    max-width:250px;
    box-shadow:0 0 8px #444;
    background:white none repeat scroll 0% 0%;
    border-radius:6px;
    padding:14px;
    position:absolute;
}
.map-marker-popup h3 {
    font-size:15px;
}
.map-marker-popup .distribuidorsearch-postsInfo {
    font-size:12px;
}
.map-marker-popup::after{
    content:"";
    position:absolute;
    width:0;
    height:0;
    margin-left:-8px;
    bottom:-15px;
    left:50%;
    box-sizing:border-box;
    border:8px solid black;
    border-color:transparent transparent #FFF #FFF;

    transform-origin:0 0;
    transform:rotate(-45deg);

    box-shadow:-3px 3px 3px 0 rgba(0, 0, 0, 0.4);
}
.distribuidorsearch-city .input-g__ico {
    border-radius:0 5px 5px 0 !important;
}
#searchdistribuidor h3 u {
    text-decoration:none;
    position:relative;
    font-style:normal;
    padding-bottom:7px;
    display:inline-block;
}
#searchdistribuidor h3 u::before {
    content:"";
    background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
    background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
    background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
    height:4px;
    width:100%;
    position:absolute;
    bottom:0;
    left:0;
}
.distribuidorsearch-postsItem.active {
    background:#e2e2e2;
}
.distribuidorsearch-postsItem {
    display:block;
}
.gm-style-iw-c button.gm-ui-hover-effect {
    top:2px !important;
    right:2px !important;
}
.gm-style .gm-style-iw-c {
    padding:18px !important;
}
.gm-style-iw-c .distribuidorsearch-postsTitle > h3 {
    font-size:16px;
}
.gm-style .gm-style-iw-d {
    max-width:250px;
}
@media (max-width:767px) {
    .block-g--distribuidores .title-g--border::before {
        width:57%;
        right:0;
        margin:auto;
    }
    .distribuidorsearch-posts {
        display:none;
    }
    .distribuidorsearch__map {
        height:600px;
    }
    .distribuidorsearch-city {
        width:100%;
    }
    .distribuidores-form .form-block__b {
        padding-bottom:50px;
    }
}
/* Desktop */
@media (min-width:1200px) and (max-width:1600px) {
    .block-g--distribuidores .block-g__image {
        max-width:76px;
    }
    .distribuidores-form .form-block__nav {
        padding-top:36px;
        padding-bottom:36px;
    }
    .distribuidorsearch-search .title-g__title h1 {
        letter-spacing:1.6px;
        line-height:1.1;
    }
    .distribuidorsearch-search .input-g__title {
        font-family:"TTNorms-Bold",sans-serif;
    }
    .distribuidorsearch-postsTitle > h3 {
        font-size:18px;
    }
    .distribuidorsearch-title {
        margin-bottom:32px;
    }
    .distribuidorsearch-form {
        margin-bottom:32px;
    }
    .distribuidorsearch-search {
        width:380px;
        justify-content:flex-start;
    }
    .distribuidorsearch-postsContent {
        font-size:13px;
        padding-bottom:16px;
    }
    .distribuidorsearch-postsInfo {
        font-size:13px;
    }
    .distribuidorsearch-postsItem {
        padding-bottom:0;
    }
    .distribuidorsearch-postsItem:not(:last-child) .distribuidorsearch-postsContent {
        border-bottom:solid 1px #757575;
    }
    .distribuidorsearch-posts {
        flex-grow:1;
    }
    .block-distribuidor__item .title-g__icon {
        max-width:64px;
    }
    .block-g--distribuidores .block-g__item {
        margin:0;
    }
    .block-g--distribuidores .g__textIn {
        text-align:center;
    }
}
/*  Form Wizard */
#form-wizard {
    position: relative;
    margin: 0;
    padding: 0;
    height: auto;
    overflow:hidden;
}
#form-wizard > ul {
    margin:0;
    list-style: none;
}
#form-wizard > ul > li {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
}
