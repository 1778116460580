
.login-username label {
	display:none !important
}
.login-password label {
	display:none !important
}
.portalgp-blockInfoLogin {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	border-radius:15px;
	overflow:hidden;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap
}
.portalgp-blockInfoLogin.garantia {
	-webkit-box-orient:horizontal;
	-webkit-box-direction:reverse;
	-ms-flex-direction:row-reverse;
	flex-direction:row-reverse
}
.portalgp-blockInfoLogin.garantia .portalgp-blockInfo {
	background:#E6E6E6
}
.portalgp-blockInfoLogin.garantia .portalgp-blockLogin {
	background:#fff
}
.portalgp-blockInfoLogin.garantia .portalgp-blockLogin input[type='text'], .portalgp-blockInfoLogin.garantia .portalgp-blockLogin input[type='password'] {
	background:#E6E6E6 !important
}
.portalgp-blockInfo {
	background:#fff;
	padding:50px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	width:50%;
	height:610px;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center
}
.portalgp-blockInfoTitle > h3 {
	font-size:29px;
	line-height:1.5;
	letter-spacing:1.42px;
	color:#1D1D1B;
	font-family:"TTNorms-Light", sans-serif
}
.portalgp-blockInfoText {
	font-size:17px;
	line-height:28px;
	font-family:"TTNorms-Light", sans-serif;
	color:#1D1D1B;
	line-height:1.5
}
.portalgp-blockLoginTitle > h3 {
	font-size:29px;
	line-height:1.5;
	letter-spacing:1.42px;
	color:#1D1D1B;
	font-family:"TTNorms-Light", sans-serif
}
.portalgp-blockInfoButton {
	margin-top:50px
}
.portalgp-blockLogin {
	background:#E6E6E6;
	width:50%;
	height:610px;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	padding:50px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
.portalgp-blockLogin input[type='text'], .portalgp-blockLogin input[type='password'] {
	width:100% !important;
	height:40px !important;
	line-height:40px !important;
	-webkit-box-sizing:border-box !important;
	box-sizing:border-box !important;
	border:none !important;
	margin:0 !important;
	padding:0 !important;
	border-radius:5px !important;
	background:none !important;
	-webkit-box-shadow:none !important;
	box-shadow:none !important;
	font-size:18px !important;
	color:#000 !important;
	font-family:"TTNorms-Regular", sans-serif !important;
	background-color:#fff !important;
	padding:0 15px !important
}
.portalgp-blocks {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-pack:justify;
	-ms-flex-pack:justify;
	justify-content:space-between;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap;
	margin-top:35px
}
.portalgp-block2 {
	width:40%
}
.portalgp-blockLoginForm form {
	margin:0 !important
}
@media (max-width:767px) {
	.portalgp-block2 {
		width:29%
	}
	.portalgp-blockLogin {
		padding:40px
	}
	.portalgp-blockLogin {
		padding:40px
	}
	.portalgp-blockInfoTitle > h3 {
		font-size:25px
	}
	.portalgp-blockLoginTitle > h3 {
		font-size:25px
	}
}
@media (max-width:767px) {
	.portalgp-block2 {
		width:100%;
		-webkit-box-ordinal-group:2;
		-ms-flex-order:1;
		order:1;
		padding:0 30px;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
	.portalgp-blockInfo {
		width:100%;
		padding-top:60px;
		padding-bottom:60px;
		height:auto
	}
	.portalgp-blockLogin {
		width:100%
	}
	.portalgp-blockInfoTitle > h3 {
		font-size:14px
	}
	.portalgp-blockInfoText {
		font-size:14px
	}
	.portalgp-blockLogin {
		height:auto
	}
	.portalgp-blockLoginTitle > h3 {
		font-size:14px
	}
	.portalgp-blockInfoLogin {
		border-radius:0 !important
	}
	.portalgp-blockTit {
		margin-bottom:35px
	}
}
.woocommerce-button, .woocommerce-Button, .button {
	height:50px !important;
	line-height:50px !important;
	padding:0 !important;
	padding:0 30px !important;
	border-radius:8px !important;
	letter-spacing:1.13px !important;
	color:#fff !important;
	font-family:"TTNorms-Medium", sans-serif !important;
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c)) !important;
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%) !important;
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%) !important;
	text-transform:uppercase !important;
	border-radius:8px !important;
	font-size:15px !important;
	-webkit-transition:all 0.3s ease-in-out !important;
	-o-transition:all 0.3s ease-in-out !important;
	transition:all 0.3s ease-in-out !important
}
@media (min-width:1025px) {
	.woocommerce-button:hover, .woocommerce-Button:hover, .button:hover {
		-webkit-transform:scale(1.06);
		-ms-transform:scale(1.06);
		transform:scale(1.06);
		-webkit-box-shadow:0px 0px 20px rgba(0, 0, 0, 0.3);
		box-shadow:0px 0px 20px rgba(0, 0, 0, 0.3)
	}
}
.woocommerce-Input, .input-text {
	height:40px !important;
	line-height:40px !important;
	-webkit-box-sizing:border-box !important;
	box-sizing:border-box !important;
	border:none !important;
	margin:0 !important;
	padding:0 !important;
	border-radius:5px !important;
	background:none !important;
	-webkit-box-shadow:none !important;
	box-shadow:none !important;
	font-size:18px !important;
	color:#000 !important;
	font-family:"TTNorms-Regular", sans-serif !important;
	background-color:#ddd !important;
	padding:0 15px !important
}
.woocommerce-Input::-webkit-input-placeholder {
	font-size:18px !important;
	color:#848483 !important;
	font-family:"TTNorms-Regular", sans-serif !important
}
.woocommerce-Input::-moz-placeholder {
	font-size:18px !important;
	color:#848483 !important;
	font-family:"TTNorms-Regular", sans-serif !important
}
.woocommerce-Input:-ms-input-placeholder {
	font-size:18px !important;
	color:#848483 !important;
	font-family:"TTNorms-Regular", sans-serif !important
}
.woocommerce-Input::-moz-placeholder {
	font-size:18px !important;
	color:#848483 !important;
	font-family:"TTNorms-Regular", sans-serif !important
}
.input-text::-webkit-input-placeholder {
	font-size:18px !important;
	color:#848483 !important;
	font-family:"TTNorms-Regular", sans-serif !important
}
.input-text::-moz-placeholder {
	font-size:18px !important;
	color:#848483 !important;
	font-family:"TTNorms-Regular", sans-serif !important
}
.input-text:-ms-input-placeholder {
	font-size:18px !important;
	color:#848483 !important;
	font-family:"TTNorms-Regular", sans-serif !important
}
.input-text::-moz-placeholder {
	font-size:18px !important;
	color:#848483 !important;
	font-family:"TTNorms-Regular", sans-serif !important
}
@media (max-width:1024px) {
	.woocommerce-button, .woocommerce-Button, .button {
		height:45px !important;
		line-height:45px !important;
		font-size:13px !important
	}
}
@media (max-width:1024px) {
	.woocommerce-button, .woocommerce-Button, .button {
		height:35px !important;
		line-height:35px !important;
		font-size:13px !important
	}
	.woocommerce-Input, .input-text {
		height:35px !important;
		line-height:35px !important;
		font-size:14px !important
	}
	.woocommerce-Input::-webkit-input-placeholder {
		font-size:14px !important
	}
	.woocommerce-Input::-moz-placeholder {
		font-size:14px !important
	}
	.woocommerce-Input:-ms-input-placeholder {
		font-size:14px !important
	}
	.woocommerce-Input::-moz-placeholder {
		font-size:14px !important
	}
	.input-text::-webkit-input-placeholder {
		font-size:14px !important
	}
	.input-text::-moz-placeholder {
		font-size:14px !important
	}
	.input-text:-ms-input-placeholder {
		font-size:14px !important
	}
	.input-text::-moz-placeholder {
		font-size:14px !important
	}
	.woocommerce-privacy-policy-text {
		font-size:14px !important
	}
	.woocommerce-form-row {
		font-size:14px !important
	}
	.woocommerce-orders-table__row {
		font-size:14px !important
	}
	.woocommerce-Address-title > h3 {
		font-size:16px
	}
}
@media (max-width:400px) {
	.woocommerce-button, .woocommerce-Button, .button {
		font-size:12px !important;
		padding:0 10px !important
	}
	.woocommerce-Input, .input-text {
		padding:0 10px !important;
		font-size:12px !important
	}
}
