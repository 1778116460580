.message-g {
	height:780px;
	position:relative;
	overflow:hidden;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	background:#fff;
	background-position:center center;
	background-size:cover;
	background-repeat:no-repeat
}
.message-g__container {
	max-width:1500px
}
.message-g__info {
	width:50%
}
.message-g__title {
	width:100%
}
.message-g__title h1 {
	font-size:72px;
	line-height:1.27;
	margin:0;
	font-family:"TTNorms-Light", sans-serif;
	color:#000;
	margin-bottom:10px;
	letter-spacing:2.5px
}
.message-g__title h3 {
	font-family:"TTNorms-Bold", sans-serif;
	color:#E3051B;
	font-size:72px;
	margin:0;
	letter-spacing:1.5px
}
.message-g__text {
	margin-top:45px;
	color:#000;
	font-size:27px;
	line-height:1.2;
	font-family:"TTNorms-Light", sans-serif
}
.message-g__button {
	margin-top:30px
}
.message-g__button .button-g__text {
	height:60px;
	line-height:60px
}
@media screen and (max-width:1024px) {
	.message-g__title h1 {
		font-size:50px
	}
	.message-g__title h3 {
		font-size:50px
	}
	.message-g__text {
		font-size:20px
	}
	.message-g__button .button-g__text {
		height:50px;
		line-height:50px
	}
}
@media screen and (max-width:1024px) {
	.message-g {
		height:700px;
		padding:40px 0;
		-webkit-box-sizing:border-box;
		box-sizing:border-box;
		-webkit-box-align:start;
		-ms-flex-align:start;
		align-items:flex-start
	}
	.message-g__info {
		width:100%
	}
	.message-g__title h1 {
		font-size:40px
	}
	.message-g__title h3 {
		font-size:40px
	}
	.message-g__text {
		font-size:14px
	}
	.message-g__button .button-g__text {
		height:40px;
		line-height:40px;
		border-radius:15px
	}
}
