.productpage__wrap {
	position:relative
}
.productpage {
	position:relative;
	height:780px;
	overflow:hidden
}
.productpage__item {
	position:relative;
	height:780px;
	overflow:hidden
}
.productpage__bg {
	position:relative;
	height:780px;
	width:100%;
	background-position:center center;
	background-size:cover;
	background-repeat:no-repeat
}
.productpage__container {
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	margin:auto;
	height:780px;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	-webkit-box-pack:justify;
	-ms-flex-pack:justify;
	justify-content:space-between;
	position:absolute;
	top:0;
	left:0;
	right:0;
	width:100%;
	z-index:10;
	padding-top:30px;
	box-sizing:border-box
}
.productpage__info {
	width:36%;
	margin-right:0;
	margin-left:auto;
	padding-bottom:20px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
.productpage__infoTitle h2 {
	font-size:72px;
	letter-spacing:5.4px;
	line-height:1.2;
	font-family:"TTNorms-Light", sans-serif
}
.productpage__infoTitle h2 strong {
	font-weight:normal;
	display:block
}
.productpage__infoTitle h2 i {
	font-style:normal;
	position:relative;
	display:inline-block;
	padding-bottom:7px
}
.productpage__infoTitle h2 i:before {
	content:"";
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
	height:7px;
	width:100%;
	position:absolute;
	bottom:0;
	left:0
}
.productpage__infoText {
	margin-top:40px;
	font-size:20px;
	font-family:"TTNorms-Regular", sans-serif;
	color:#000
}
.productpage__infoButton {
	margin-top:40px
}
.productpage__infoButton .button-g {
	border-radius:10px
}
.productpage__infoButton .button-g__text {
	height:64px;
	line-height:64px;
	border-radius:10px !important
}
.productpage__arrows {
	position:absolute;
	top:0;
	left:0;
	right:0;
	width:100%;
	height:100%;
	max-width:100%;
	margin:auto;
	pointer-events:none;
	z-index:3;
	font-size:0
}
.productpage__prev, .productpage__next {
	position:absolute;
	top:50%;
	-webkit-transform:translateY(-50%);
	-ms-transform:translateY(-50%);
	transform:translateY(-50%);
	background-repeat:no-repeat;
	background-position:0 0;
	background-size:100%;
	cursor:pointer;
	text-align:center;
	display:block;
	pointer-events:auto
}
.productpage__prev {
	left:20px
}
.productpage__next {
	right:20px
}
.productpage__prev {
	background:url("../img/icons/arrowslide-02.svg");
	width:48px;
	height:79px
}
.productpage__next {
	background:url("../img/icons/arrowslide-03.svg");
	width:48px;
	height:79px
}
.productpage__pagination {
	margin-top:50px
}
.productpage__pagination.swiper-pagination {
	bottom:40px !important;
	margin:0
}
.productpage__pagination .swiper-pagination-bullet {
	width:85px;
	height:5px;
	border-radius:0;
	background:rgba(255, 255, 255, 0.4);
	margin:0 6px !important;
	text-align:center
}
.productpage__pagination .swiper-pagination-bullet:last-child {
	margin-right:0
}
.productpage__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
	background:#fff
}
@media (max-width:1400px) {
	.productpage__info {
		width:50%
	}
	.productpage {
		height:700px
	}
	.productpage__item {
		height:700px
	}
	.productpage__container {
		height:700px;
		padding-top:0
	}
	.productpage__bg {
		height:700px
	}
	.productpage__infoTitle h2 {
		font-size:50px
	}
}
@media (max-width:1024px) {
	.productpage {
		height:600px
	}
	.productpage__item {
		height:600px
	}
	.productpage__container {
		height:600px;
		padding-top:0
	}
	.productpage__bg {
		height:600px
	}
	.productpage__infoTitle h2 {
		font-size:40px
	}
	.productpage__image {
		left:0;
		width:52%
	}
	.productpage__ancla {
		bottom:-25px
	}
	.productpage__infoButton .button-g__text {
		height:50px;
		line-height:50px
	}
}
@media (max-width:1024px) {
	.productpage {
		height:620px
	}
	.productpage__item {
		height:620px
	}
	.productpage__container {
		height:620px
	}
	.productpage__image {
		display:none
	}
	.productpage__bg {
		height:620px
	}
	.productpage__info {
		width:100%;
		height:100%;
		display:-webkit-box;
		display:-ms-flexbox;
		display:flex;
		-webkit-box-orient:vertical;
		-webkit-box-direction:normal;
		-ms-flex-direction:column;
		flex-direction:column;
		-webkit-box-pack:justify;
		-ms-flex-pack:justify;
		justify-content:space-between;
		padding:40px 0;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
	.productpage__prev {
		left:10px;
		width:8px;
		height:21px;
		background:url("../img/icons/arrowslideresp-05.svg")
	}
	.productpage__next {
		right:10px;
		width:8px;
		height:21px;
		background:url("../img/icons/arrowslideresp-04.svg")
	}
	.productpage__pagination {
		display:none
	}
	.productpage__infoTitle h2 {
		font-size:28px
	}
	.productpage__infoText {
		font-size:14px
	}
	.productpage__infoButton {
		text-align:center
	}
	.productpage__infoButton .button-g__text {
		height:40px;
		line-height:40px
	}
}
.productpage--categories {
	padding-top:90px;
	padding-bottom:90px
}
.productpage--categories .categories-block__title {
	margin-bottom:70px
}
@media (max-width:1024px) {
	.productpage--categories {
		padding-top:50px;
		padding-bottom:50px
	}
	.productpage--categories .categories-block__title {
		margin-bottom:40px
	}
}
@media (max-width:1024px) {
	.productpage--categories {
		padding-top:40px;
		padding-bottom:40px
	}
	.productpage--categories .categories-block__title {
		margin-bottom:30px;
		text-align:center;
		padding:0 40px;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
}
.productpage--familia {
	padding-bottom:70px
}
.productpage--familia .familia-block__title {
	margin-bottom:70px
}
@media (max-width:1024px) {
	.productpage--familia {
		padding-bottom:50px
	}
	.productpage--familia .familia-block__title {
		margin-bottom:50px
	}
}
@media (max-width:1024px) {
	.productpage--familia {
		padding-bottom:40px
	}
	.productpage--familia .familia-block__title {
		margin-bottom:30px
	}
}
.familia-block__slider {
	overflow:hidden
}
.familia-block__slider2 {
	overflow:hidden
}
.productpage__prev, .productpage__next {
	height:49px;
	width:17px;
	-webkit-transition:all 0.3s ease-in-out;
	-o-transition:all 0.3s ease-in-out;
	transition:all 0.3s ease-in-out;
	opacity:.5
}
.productpage__prev:hover, .productpage__next:hover {
	opacity:1
}
.productpage__prev {
	background:url("../img/icons/iconflecha-02.svg")
}
.productpage__next {
	background:url("../img/icons/iconflecha-03.svg")
}
@media (max-width:1024px) {
	.productpage__prev, .productpage__next {
		height:30px;
		width:10px
	}
}
.banner-g--catproducto .banner-g__image {
	height:780px
}
.banner-g--catproducto .banner-g__info {
	width:40%;
	margin-left:auto;
	margin-right:0
}
.banner-g--catproducto .title-g .title-g__title h1, .banner-g--catproducto .title-g .title-g__title h2, .banner-g--catproducto .title-g .title-g__title h3, .banner-g--catproducto .title-g .title-g__title h4, .banner-g--catproducto .title-g .title-g__title h5 {
	letter-spacing:5.4px
}
.banner-g--catproducto .title-g .title-g__text {
	margin-top:35px;
	line-height:1.5
}
@media (max-width:1200px) {
	.banner-g--catproducto .banner-g__info {
		width:50%
	}
}
@media (max-width:1024px) {
	.banner-g--catproducto .banner-g__image {
		height:610px;
		padding:70px 0;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
	.banner-g--catproducto .banner-g__info {
		width:100%
	}
	.banner-g--catproducto .banner-g__inside {
		-webkit-box-align:start;
		-ms-flex-align:start;
		align-items:flex-start
	}
}
.catproducto-products {
	padding-top:90px;
	padding-bottom:40px
}
.catproducto-products__miga {
	margin-bottom:40px
}
.catproducto-products__miga .miga-g > ul {
	-webkit-box-pack:end;
	-ms-flex-pack:end;
	justify-content:flex-end
}
.catproducto-products__list {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap
}
.catproducto-productsItem {
	width:32%;
	margin:0;
	margin-right:2%;
	margin-bottom:2%
}
.catproducto-productsItem:nth-child(3n+3) {
	margin-right:0
}
@media (max-width:1024px) {
	.catproducto-products {
		padding-top:50px;
		padding-bottom:30px
	}
	.catproducto-productsItem {
		width:48%;
		margin-right:4% !important;
		margin-bottom:4% !important
	}
	.catproducto-productsItem:nth-child(2n+2) {
		margin-right:0 !important
	}
}
@media (max-width:1024px) {
	.catproducto-products {
		padding-top:40px;
		padding-bottom:20px
	}
	.catproducto-products__miga {
		display:none
	}
}
@media (max-width:480px) {
	.blog-block__filter {
		padding:0 30px
	}
	.catproducto-productsItem {
		width:100%;
		margin-right:0 !important;
		margin-bottom:40px !important
	}
	.catproducto-productsItem:nth-child(2n+2) {
		margin-right:0 !important
	}
	.catproducto-productsItem:last-child {
		margin-bottom:0 !important
	}
}
.catproducto--categories .categories-block__container {
	padding:0 !important;
	max-width:100%
}
.catproducto--categories .categories-block__title {
	max-width:1680px;
	padding:0 40px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	width:100%;
	margin:auto;
	margin-bottom:60px
}
.catproducto--categories .categories-g__item {
	width:25%
}
@media (max-width:1600px) {
	.catproducto--categories .categories-g__item {
		width:33.33%
	}
}
@media (max-width:1024px) {
	.catproducto--categories .categories-g__item {
		width:50%
	}
}
@media (max-width:1024px) {
	.catproducto--categories .categories-g__item {
		width:100%
	}
}
.catproductopage--catalogo .banner-g--catalog {
	padding:0 !important;
	max-width:100% !important
}
.catproductopage--catalogo .banner-g--catalog .banner-g__container {
	max-width:100% !important
}
.block-g--catproducto {
	background:#F1F1F1;
	padding-top:100px;
	padding-bottom:90px
}
@media (max-width:1024px) {
	.block-g--catproducto {
		padding-top:70px;
		padding-bottom:60px
	}
}
@media (max-width:1024px) {
	.block-g--catproducto {
		padding-top:50px;
		padding-bottom:40px
	}
}
.banner-g--productdetail .banner-g__image {
	height:780px
}
@media (max-width:1024px) {
	.banner-g--productdetail {
		display:none
	}
}
.productdetail {
	background:#F1F1F1;
	padding-top:70px;
	padding-bottom:140px
}
.productdetail-inside {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	-webkit-box-pack:justify;
	-ms-flex-pack:justify;
	justify-content:space-between
}
.productdetail-miga {
	padding-bottom:75px
}
.productdetail-miga .miga-g > ul {
	-webkit-box-pack:end;
	-ms-flex-pack:end;
	justify-content:flex-end
}
.productdetail-image {
	width:45.8%;
	overflow:hidden;
	position:relative
}
.productdetail-image img {
	width:100%;
	height:auto;
	display:block
}
.productdetail-image .swiper-pagination-bullet {
	background:#DEDEDE;
	width:13px;
	height:13px;
	opacity:1 !important;
	margin:0 8px !important
}
.productdetail-image .swiper-pagination-bullet-active {
	background:#E3051B
}
.productdetail-info {
	width:50%
}
.productdetail-title > h1 {
	color:#E3051B;
	font-family:"TTNorms-Bold", sans-serif;
	font-size:50px;
	letter-spacing:3.75px;
	margin:0;
	margin-bottom:10px
}
.productdetail-categorie, .productdetail-family {
	letter-spacing:1.65px;
	color:#000;
	font-size:22px;
}
.productdetail-excerpt {
	font-size:20px;
	color:#000;
	margin-top:30px
}
.productdetail-excerpt ul {
	margin:0 !important;
	list-style:none !important;
	padding-left:20px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
.productdetail-excerpt li {
	line-height:1.3;
	position:relative;
	margin-bottom:10px
}
.productdetail-excerpt li:before {
	content:">";
	color:#E3051B;
	margin-right:10px
}
.productdetail-button {
	margin-top:45px
}
.productdetail-button .product_type_simple {
	line-height:50px !important;
	height:50px !important
}
@media (max-width:1200px) {
	.productdetail-title > h1 {
		font-size:30px
	}
	.productdetail-categorie, .productdetail-family {
		font-size:18px
	}
	.productdetail-excerpt {
		font-size:18px
	}
}
@media (max-width:1024px) {
	.productdetail {
		padding-top:70px;
		padding-bottom:70px
	}
	.productdetail-button .product_type_simple {
		line-height:45px !important;
		height:45px !important;
		font-size:14px !important;
		padding:0 20px !important
	}
}
@media (max-width:1024px) {
	.productdetail-image {
		width:100%
	}
	.productdetail-info {
		width:100%;
		margin-top:40px
	}
	.productdetail-miga {
		display:none
	}
}
@media (max-width:1024px) {
	.productdetail {
		padding-top:40px;
		padding-bottom:40px
	}
	.productdetail-title > h1 {
		font-size:24px
	}
	.productdetail-categorie, .productdetail-family {
		font-size:16px
	}
	.productdetail-excerpt {
		font-size:14px
	}
	.productdetail-excerpt ul {
		padding-left:0
	}
	.productdetail-button .product_type_simple {
		height:35px !important;
		line-height:35px !important;
		font-size:13px !important;
		padding:0 15px !important;
		letter-spacing:0.98px !important
	}
	.productdetail-button .added_to_cart {
		height:35px !important;
		line-height:35px !important;
		font-size:13px !important;
		padding:0 15px !important;
		letter-spacing:0.98px !important
	}
	.productdetail-image .swiper-pagination-bullet {
		width:10px;
		height:10px;
		margin:0 5px
	}
	.productdetail-image .swiper-container-horizontal > .swiper-pagination-bullets {
		bottom:0
	}
}
.productaccesories {
	background:#F1F1F1;
	padding-top:65px;
	padding-bottom:70px
}
.productaccesories .arrow-g__buttonPrev {
	left:-25px
}
.productaccesories .arrow-g__buttonNext {
	right:-25px
}
.productaccesories-title {
	margin-bottom:65px
}
.productaccesories-slider {
	overflow:hidden
}
@media (max-width:1600px) {
	.productaccesories .arrow-g__buttonPrev {
		left:10px
	}
	.productaccesories .arrow-g__buttonNext {
		right:10px
	}
}
@media (max-width:1024px) {
	.productaccesories {
		padding-top:30px;
		padding-bottom:30px
	}
	.productaccesories-title {
		margin-bottom:30px
	}
	.productaccesories-title .title-g__title h1, .productaccesories-title .title-g__title h2, .productaccesories-title .title-g__title h3, .productaccesories-title .title-g__title h4, .productaccesories-title .title-g__title h5 {
		font-size:14px !important
	}
}
@media (max-width:479px) {
	.productaccesories-container {
		padding:0 30px !important
	}
	.productaccesories .arrow-g__buttonPrev {
		left:10px
	}
	.productaccesories .arrow-g__buttonNext {
		right:10px
	}
}
.productvideo {
	background:#fff;
	padding-top:65px;
	padding-bottom:70px
}
.productvideo .item-g__accesorieVideoInfo {
	background:#F1F1F1
}
.productvideo .arrow-g__buttonPrev {
	left:-25px
}
.productvideo .arrow-g__buttonNext {
	right:-25px
}
.productvideo-title {
	margin-bottom:65px
}
.productvideo-slider {
	overflow:hidden
}
@media (max-width:1600px) {
	.productvideo .arrow-g__buttonPrev {
		left:10px
	}
	.productvideo .arrow-g__buttonNext {
		right:10px
	}
}
@media (max-width:1024px) {
	.productvideo {
		padding-top:30px;
		padding-bottom:30px
	}
	.productvideo-title {
		margin-bottom:30px
	}
	.productvideo-title .title-g__title h1, .productvideo-title .title-g__title h2, .productvideo-title .title-g__title h3, .productvideo-title .title-g__title h4, .productvideo-title .title-g__title h5 {
		font-size:14px !important
	}
}
@media (max-width:479px) {
	.productvideo-container {
		padding:0 30px !important
	}
	.productvideo .arrow-g__buttonPrev {
		left:10px
	}
	.productvideo .arrow-g__buttonNext {
		right:10px
	}
}
.productrel {
	background:#F1F1F1;
	padding-top:65px;
	padding-bottom:70px
}
.productrel .arrow-g__buttonPrev {
	left:-25px
}
.productrel .arrow-g__buttonNext {
	right:-25px
}
.productrel-title {
	margin-bottom:65px
}
.productrel-slider {
	overflow:hidden
}
@media (max-width:1600px) {
	.productrel .arrow-g__buttonPrev {
		left:10px
	}
	.productrel .arrow-g__buttonNext {
		right:10px
	}
}
@media (max-width:1024px) {
	.productrel {
		padding-top:30px;
		padding-bottom:30px
	}
	.productrel-title {
		margin-bottom:30px
	}
	.productrel-title .title-g__title h1, .productrel-title .title-g__title h2, .productrel-title .title-g__title h3, .productrel-title .title-g__title h4, .productrel-title .title-g__title h5 {
		font-size:14px
	}
}
@media (max-width:479px) {
	.productrel-container {
		padding:0 30px !important
	}
	.productrel .arrow-g__buttonPrev {
		left:10px
	}
	.productrel .arrow-g__buttonNext {
		right:10px
	}
}
.productd--categories {
	padding-top:65px
}
.productd--categories .categories-block__container {
	padding:0 !important;
	max-width:100%
}
.productd--categories .categories-block__title {
	max-width:1680px;
	padding:0 40px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	width:100%;
	margin:auto;
	margin-bottom:60px
}
.productd--categories .categories-g__item {
	width:25%
}
@media (max-width:1600px) {
	.productd--categories .categories-g__item {
		width:33.33%
	}
}
@media (max-width:1024px) {
	.productd--categories {
		padding-top:50px
	}
	.productd--categories .categories-g__item {
		width:50%
	}
}
@media (max-width:1024px) {
	.productd--categories {
		padding-top:40px
	}
	.productd--categories .categories-g__item {
		width:100%
	}
	.productd--categories .categories-block__title {
		margin-bottom:30px;
		padding:0 30px
	}
	.productd--categories .categories-block__title .title-g__title h1, .productd--categories .categories-block__title .title-g__title h2, .productd--categories .categories-block__title .title-g__title h3, .productd--categories .categories-block__title .title-g__title h4, .productd--categories .categories-block__title .title-g__title h5 {
		font-size:14px
	}
}
.productd-tabIdealItems {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap
}
.productd-tabIdealItem {
	text-align:center;
	margin:12px 30px 12px 0
}
.productd-tabIdealItemImage {
	width:65px;
	height:65px;
	margin:0
}
.productd-tabIdealItemImage img {
	width:100%;
	height:auto;
	display:block
}
.productd-tabIdealItemTitle {
	margin-top:5px
}
.productd-tabIdealItemTitle > h3 {
	font-size:14px;
	color:#3C3C3B;
    line-height:1.2;
}
.productd-tabCatApto {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex
}
.productd-tabApto {
	margin-left:280px
}
.productd-tabCatName > h3 {
	font-size:15px;
	letter-spacing:0.21px;
	color:#000;
	font-family:"TTNorms-Bold", sans-serif;
	text-transform:uppercase
}
.productd-tabAptoList > ul {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center
}
.productd-tabAptoList > ul > li {
	font-family:"TTNorms-Bold", sans-serif;
	font-size:15px;
	letter-spacing:0.21px;
	color:#B2B2B2;
	text-transform:uppercase;
	padding-bottom:5px;
	position:relative;
	margin:0 10px
}
.productd-tabAptoList > ul > li:before {
	content:"";
	position:absolute;
	bottom:0;
	left:0;
	width:0;
	height:2px;
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%)
}
.productd-tabAptoList > ul > li.active {
	color:#000
}
.productd-tabAptoList > ul > li.active:before {
	width:100%
}
.item-g__familia:hover .item-g__familiaImage img {
	-webkit-transform:scale(1.1);
	-ms-transform:scale(1.1);
	transform:scale(1.1)
}
.item-g__familiaImage {
	width:100%;
	margin:0;
	overflow:hidden
}
.item-g__familiaImage img {
	-webkit-transition:all .3s ease-in-out;
	-o-transition:all .3s ease-in-out;
	transition:all .3s ease-in-out;
	width:100%;
	height:auto;
	display:block
}
.item-g__familiaInfo {
	padding:45px;
	background:#F1F1F1;
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
.item-g__familiaTitle > h3 {
	letter-spacing:2.7px;
	font-size:36px;
	font-family:"TTNorms-Regular", sans-serif;
	color:#E3051B
}
.item-g__familiaText {
	color:#000;
	font-family:"TTNorms-Regular", sans-serif;
	font-size:15px;
	line-height:1.3
}
@media (max-width:1024px) {
	.item-g__familiaInfo {
		padding:40px 30px
	}
	.item-g__familiaTitle > h3 {
		font-size:20px
	}
	.item-g__familiaText {
		font-size:14px
	}
}
.productaccesories .item-g__accesorieVideo:hover .item-g__accesorieVideoImage img {
	opacity:1
}
.item-g__accesorieVideo:hover .item-g__accesorieVideoImage img {
	-webkit-transform:scale(1.1);
	-ms-transform:scale(1.1);
	transform:scale(1.1);
	opacity:.8
}
.item-g__accesorieVideoImage {
	width:100%;
	margin:0;
	position:relative;
	overflow:hidden
}
.item-g__accesorieVideoImage img {
	width:100%;
	height:auto;
	display:block;
	-webkit-transition:all .3s ease-in-out;
	-o-transition:all .3s ease-in-out;
	transition:all .3s ease-in-out
}
.item-g__accesorieVideoImage > span {
	position:absolute;
	top:50%;
	left:50%;
	display:block;
	-webkit-transform:translate(-50%, -50%);
	-ms-transform:translate(-50%, -50%);
	transform:translate(-50%, -50%);
	font-size:40px;
	color:#fff;
	z-index:2
}
.item-g__accesorieVideoInfo {
	padding:45px;
	background:#fff;
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
.item-g__accesorieVideoTitle {
	margin-bottom:10px;
	max-width:80%
}
.item-g__accesorieVideoTitle > h3 {
	letter-spacing:1.2px;
	font-size:16px;
	line-height:1.4;
	font-family:"TTNorms-Light", sans-serif;
	color:#E3051B;
	margin:0
}
.item-g__accesorieVideoText {
	max-width:80%;
	color:#3C3C3B;
	font-family:"TTNorms-Regular", sans-serif;
	font-size:17px;
	line-height:1.3
}
.item-g__accesorieVideoText p {
	margin-bottom:5px
}
@media (max-width:1024px) {
	.item-g__accesorieVideoInfo {
		padding:40px 30px
	}
	.item-g__accesorieVideoTitle {
		max-width:100%
	}
	.item-g__accesorieVideoTitle > h3 {
		font-size:16px
	}
	.item-g__accesorieVideoText {
		font-size:14px;
		max-width:100%
	}
}
.item-g__product3:hover .item-g__product3Image img {
	-webkit-transform:scale(1.1);
	-ms-transform:scale(1.1);
	transform:scale(1.1)
}
.item-g__product3Image {
	width:100%;
	margin:0;
	background:#F7F7F7;
	overflow:hidden
}
.item-g__product3Image img {
	width:100%;
	height:auto;
	display:block;
	-webkit-transition:all .3s ease-in-out;
	-o-transition:all .3s ease-in-out;
	transition:all .3s ease-in-out
}
.item-g__product3Info {
	padding:45px;
	background:#fff;
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
.item-g__product3Title > h3 {
	font-size:15px;
	font-family:"TTNorms-Regular", sans-serif;
	color:#E3051B
}
.item-g__product3Text {
	color:#000;
	font-family:"TTNorms-Regular", sans-serif;
	font-size:15px;
	line-height:1.3
}
.item-g__product3Cat > h4 {
	color:#000;
	font-family:"TTNorms-Regular", sans-serif;
	font-size:15px;
	margin:0
}
@media (max-width:1024px) {
	.item-g__product3Info {
		padding:40px 30px
	}
	.item-g__product3Title > h3 {
		font-size:14px
	}
	.item-g__product3Text {
		font-size:14px
	}
	.item-g__product3Cat > h4 {
		font-size:14px
	}
}

.productd-tabacordion__list {
	margin-top:-56px
}
.productd-tabacordion__list > ul {
	display:table;
	width:100%
}
.productd-tabacordion__list > ul > li {
	display:table-cell;
	vertical-align:middle;
	text-align:center;
	height:56px;
	padding:15px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	font-size:15px;
	letter-spacing:1.1px;
	color:#3C3C3B;
	background:#fff;
	text-transform:uppercase;
	border-right:1px solid #E5E5E5;
	border-bottom:1px solid #E5E5E5;
	border-top:1px solid #E5E5E5;
	cursor:pointer
}
.productd-tabacordion__list > ul > li:first-child {
	border-left:1px solid #E5E5E5
}
.productd-tabacordion__list > ul > li.active {
	font-family:"TTNorms-Bold", sans-serif;
	border-bottom:1px solid #fff
}
.productd-tabContent {
	position:relative
}
.productd-tabContentItem {
	padding:40px 0
}
.productd-tabContentItem .title-g__title {
	margin-bottom:20px
}
.productd-tabContentItem .title-g__title h1, .productd-tabContentItem .title-g__title h2, .productd-tabContentItem .title-g__title h3, .productd-tabContentItem .title-g__title h4, .productd-tabContentItem .title-g__title h5 {
	letter-spacing:0.21px !important
}
@media (max-width:1600px) {
	.productd-tabacordion__list {
		margin-top:0
	}
}
.productd-tabLineIn {
	padding:30px 0;
	border-top:1px solid #E4E4E4
}
.productd-tabTitle {
	margin-bottom:20px
}
.productd-tabTitle > h2 {
	font-size:36px;
	color:#E3051B;
	line-height:1.2;
	font-family:"TTNorms-Bold", sans-serif;
	letter-spacing:2.7px;
	margin:0
}
.productd-tabContent {
	font-size:17px;
	color:#3C3C3B;
	line-height:1.4;
}
.productd-insideItem {
	padding:20px 40px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	border-bottom:1px solid #E5E5E5;
	display:none
}
.productd-insideItem > h3 {
	font-size:14px;
	letter-spacing:1.05px;
	text-transform:uppercase;
	color:#3C3C3B;
	font-family:"TTNorms-Regular", sans-serif;
	margin:0
}
@media (max-width:1024px) {
	.productd-tabContentItem .title-g__title h1, .productd-tabContentItem .title-g__title h2, .productd-tabContentItem .title-g__title h3, .productd-tabContentItem .title-g__title h4, .productd-tabContentItem .title-g__title h5 {
		font-size:20px !important
	}
	.productd-tabCatApto {
		-webkit-box-pack:justify;
		-ms-flex-pack:justify;
		justify-content:space-between
	}
	.productd-tabApto {
		margin:0
	}
}
@media (max-width:1024px) {
	.productd-tabTitle > h2 {
		font-size:16px;
		letter-spacing:0.4px
	}
	.productd-tabContent {
		font-size:14px
	}
	.productd-tabContentItem .title-g__title h1, .productd-tabContentItem .title-g__title h2, .productd-tabContentItem .title-g__title h3, .productd-tabContentItem .title-g__title h4, .productd-tabContentItem .title-g__title h5 {
		font-size:16px !important
	}
	.productd-tabCatName > h3 {
		font-size:14px
	}
	.productd-tabCatApto {
		-ms-flex-wrap:wrap;
		flex-wrap:wrap
	}
	.productd-tabApto {
		margin-left:0;
		padding-top:40px;
		border-top:1px solid #E4E4E4;
		margin-top:40px;
		width:100%
	}
	.productd-tabAptoList > ul > li {
		font-size:14px
	}
	.productd-tabContentItem.descripcion .productd-insideIn {
		padding-left:30px;
		padding-right:30px
	}
	.productd-inside {
		max-height:0;
		overflow:hidden;
		-webkit-transition:max-height 0.2s ease-out;
		-o-transition:max-height 0.2s ease-out;
		transition:max-height 0.2s ease-out
	}
	.productd-tabacordion__container {
		padding:0 !important
	}
	.productd-tabContentItem {
		padding:0
	}
	.productd-insideIn {
		padding:20px 0
	}
	.productd-insideTitle {
		display:none !important
	}
	.productd-tabacordion__list {
		display:none
	}
	.productd-insideItem {
		position:relative;
		display:block
	}
	.productd-insideItem.active > span:before {
		opacity:0;
		visibility:hidden
	}
	.productd-insideItem.active > span:after {
		opacity:1;
		visibility:visible
	}
	.productd-insideItem > span {
		position:absolute;
		top:50%;
		-webkit-transform:translateY(-50%);
		-ms-transform:translateY(-50%);
		transform:translateY(-50%);
		right:25px;
		font-size:25px;
		font-family:"TTNorms-Bold", sans-serif;
		color:#E3051B
	}
	.productd-insideItem > span:before, .productd-insideItem > span:after {
		position:relative
	}
	.productd-insideItem > span:before {
		content:"+"
	}
	.productd-insideItem > span:after {
		content:"-";
		position:absolute;
		left:0;
		opacity:0;
		visibility:hidden
	}
}
.productd-especificacionesListItem {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap;
	padding:35px 20px;
	border-bottom:1px solid #F2F2F2
}
.productd-especificacionesListItem:first-child {
	border-top:1px solid #F2F2F2
}
.productd-especificacionesListItem:nth-child(even) {
	background:#F2F2F2
}
.productd-especificacionesListTitle {
	width:325px;
	padding-right:15px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
.productd-especificacionesListTitle > h3 {
	letter-spacing:0.85px;
	font-size:17px;
	color:#000;
	margin:0;
	line-height:1.3
}
.productd-especificacionesListText {
	width:calc(100% - 325px)
}
.productd-especificacionesListText > ul > li {
	position:relative;
	letter-spacing:0.85px;
	font-size:17px;
	color:#000;
	line-height:1.2;
	margin-bottom:15px
}
.productd-especificacionesListText > ul > li:last-child {
	margin-bottom:0
}
.productd-especificacionesListText > ul > li:before {
	content:">";
	margin-right:10px;
	color:#E3051B
}
@media (max-width:1024px) {
	.productd-especificacionesListTitle {
		width:250px
	}
	.productd-especificacionesListText {
		width:calc(100% - 250px)
	}
}
@media (max-width:1024px) {
	.productd-especificacionesListItem {
		padding:35px 30px
	}
	.productd-especificacionesListItem:first-child {
		border-top:none
	}
	.productd-especificacionesListTitle {
		width:100%;
		margin-bottom:30px
	}
	.productd-especificacionesListText {
		width:100%
	}
	.productd-especificacionesListText > ul > li {
		font-size:14px
	}
	.productd-especificacionesListTitle > h3 {
		font-size:14px
	}
}
.productd-tabContentItem.casosdeexito .home-clients {
	padding:0;
	background:#fff
}
.productd-tabContentItem.casosdeexito .home-clients .item-g__oneInfo {
	background:#F1F1F1
}
.productd-tabContentItem.descargas .filter-block__itemDescarga {
	margin-bottom:0
}
.productd-tabContentItem.descargas .item-descarga {
	background:#fff
}
.productd-tienda .title-g__text {
	margin-top:40px
}
.productd-tienda .title-g__buttons {
	margin-top:40px
}
.productd-distribuidores {
	margin-top:60px
}
.productd-distribuidores form {
	margin:0 !important
}
.productd-distribuidores .title-g__text {
	margin-top:40px
}
.productd-distribuidores__form {
	max-width:630px;
	margin-left:0;
	margin-right:auto;
	margin-top:40px
}
.productd-distribuidores__forminputs > ul > li {
	margin-bottom:20px
}
.productd-distribuidores__forminputs > ul > li:last-child {
	margin-bottom:0
}
.productd-distribuidores__formbtns {
	margin-top:40px;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	-webkit-box-pack:justify;
	-ms-flex-pack:justify;
	justify-content:space-between
}
@media (max-width:1024px) {
	.productd-tabContentItem.dondecomprar .productd-insideIn {
		padding-left:30px;
		padding-right:30px
	}
}
@media (max-width:480px) {
	.productd-distribuidores__formbtns {
		-ms-flex-wrap:wrap;
		flex-wrap:wrap
	}
	.productd-distribuidores__formbtn1 {
		margin-bottom:30px
	}
}
.productd-solicitademostracion {
	max-width:870px;
	width:100%;
	margin-right:auto;
	margin-left:0
}
@media (max-width:1024px) {
	.productd-tabContentItem.solicitademostracion .productd-insideIn {
		padding-left:30px;
		padding-right:30px
	}
}
.productd-registraproducto .title-g__text {
	margin-top:40px
}
.productd-registraproducto .title-g__buttons {
	margin-top:40px
}
.productd-garantia {
	margin-top:60px
}
.productd-garantia .title-g__text {
	margin-top:40px
}
.productd-garantia .title-g__buttons {
	margin-top:40px
}
@media (max-width:1024px) {
	.productd-tabContentItem.registro .productd-insideIn {
		padding-left:30px;
		padding-right:30px
	}
}
.product-compromisos {
	padding-top:40px;
	padding-bottom:50px;
	background:#F2F2F2
}
.product-compromisos .block-g__textIn {
	display:none
}
.product-compromisos__title {
	margin-bottom:40px
}
.productd-tabContentItem2 {
	padding:40px 0
}
@media (max-width:1024px) {
	.product-compromisos .block-g__item {
		padding-top:15px;
		padding-bottom:15px
	}
}
@media (max-width:1024px) {
	.productd-tabContentItem2 {
		padding:0
	}
	.product-compromisos .block-g__textIn {
		display:block
	}
	.product-compromisos .block-g__item {
		padding-top:0;
		padding-bottom:0
	}
}
.product-compatiblecon {
	padding-top:50px;
	padding-bottom:50px;
	background:#fff
}
.product-compatiblecon .arrow-g__buttonPrev {
	left:-25px
}
.product-compatiblecon .arrow-g__buttonNext {
	right:-25px
}
.product-compatiblecon__title {
	margin-bottom:40px
}
.product-compatiblecon__slider {
	overflow:hidden
}
@media (max-width:1600px) {
	.product-compatiblecon .arrow-g__buttonPrev {
		left:10px
	}
	.product-compatiblecon .arrow-g__buttonNext {
		right:10px
	}
}
@media (max-width:479px) {
	.product-compatiblecon-container {
		padding:0 30px !important
	}
	.product-compatiblecon .arrow-g__buttonPrev {
		left:10px
	}
	.product-compatiblecon .arrow-g__buttonNext {
		right:10px
	}
}
.productd-tabacordion.tienda .productd-tabacordion__list {
	max-width:970px;
	width:100%;
	margin-left:0;
	margin-right:auto;
	margin-top:0
}
@media (max-width:767px) {
	.productdetail-title > h1 {
		font-size:20px
	}
}
@media (min-width:768px) {
	.tab-g__contentItem {
		position:absolute;
		top:0;
		opacity:0;
		visibility:hidden;
		-webkit-transition:all 0.3s ease-in-out;
		-o-transition:all 0.3s ease-in-out;
		transition:all 0.3s ease-in-out
	}
	.tab-g__contentItem.active {
		position:relative;
		opacity:1;
		visibility:visible
	}
}
@media (min-width:768px) and (max-width:1024px) {
    .tab-g__contentItem {
		position:static;
		opacity:1;
		visibility:visible;
    }
}
.banner-g--catalog {
	max-width:1680px;
	width:100%;
	margin:0 auto;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	padding:0 40px
}
.banner-g--catalog:hover .banner-g__image {
	-webkit-transform:scale(1.1);
	-ms-transform:scale(1.1);
	transform:scale(1.1)
}
.banner-g--catalog .banner-g__image {
	height:400px
}
.banner-g--catalog .banner-g__info {
	max-width:345px;
	width:100%;
	margin-right:0;
	margin-left:auto
}
.banner-g--catalog .banner-g__inside {
	max-width:1280px
}
.banner-g--catalog .banner-g__container {
	overflow:hidden;
	width:100%;
	padding:0 !important;
	margin:0
}
@media (max-width:1024px) {
	.banner-g--catalog {
		padding:0 !important
	}
	.banner-g--catalog .banner-g__image {
		height:600px
	}
	.banner-g--catalog .banner-g__inside {
		-webkit-box-align:end;
		-ms-flex-align:end;
		align-items:flex-end;
		padding-bottom:45px;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
	.banner-g--catalog .banner-g__info {
		max-width:300px;
		margin:0 auto
	}
	.banner-g--catalog .banner-g__container {
		padding:0 !important
	}
}
/*  Fixes   */
.productd-tabIdealItemImage {
    margin:0 auto;
}
.productrel {
    background:#F7F7F7;
}
.productpage__container {
    height:580px;
}
.productpage__bg {
    height:580px;
}
.productd-tabCatName > h3 {
    position:relative;
    padding-bottom:6px;
    display:inline;
}
.productd-tabCatName h3::before {
    content:"";
    position:absolute;
    bottom:0;
    left:0;
    width:0;
    height:2px;
    background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
    background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
    background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
    width:100%;
}
@media (max-width:767px) {
    .productd-tabIdealItemTitle > h3 {
        font-size:10px;
    }
    .categories-g__item {
        height:120px;
    }
    .item-g__familiaTitle > h3 {
        font-size:17px;
    }
    .item-g__familiaText {
        display:none;
    }
    .item-g__familiaInfo {
        padding:20px 30px;
    }
    .item-g__product3Cat {
        display:none;
    }
    .item-g__product3Info {
        padding:20px 30px;
    }
    .productd-tabIdealItems {
        display:grid;
        grid-template-columns:repeat(auto-fit, minmax(100px, 1fr));
        align-items:start;
    }
    .productd-tabIdealItem {
        margin:0;
    }
}
/* Tablet */
@media (min-width:768px) and (max-width:1024px) {
    .productdetail-excerpt {
        font-size: 15px;
    }
    .productd--categories .categories-block__title {
        padding-left:60px;
    }
    .productd-tabIdealItemTitle>h3 {
        font-size: 11px;
    }
    .productd-tabCatName>h3 {
        padding-bottom:8px;
    }
    .productd-insideIn {
        padding:0;
    }
    .productd-tabContentItem.descripcion .productd-insideIn,
    .productd-tabContentItem.registro .productd-insideIn,
    .productd-tabContentItem.dondecomprar .productd-insideIn,
    .productd-especificacionesListItem {
        padding: 35px 60px!important;
    }
    .item-descarga__listTitle > h4,
    .productd-tabContentItem .title-g__title h3,
    .productd-tabTitle > h2 {
       font-size:14px!important;
    }
    .productd-tabAptoList>ul> li:first-child {
       margin-left:0!important;
    }
    .productd-distribuidores {
       margin-top:0;
    }
    .productd-insideItem {
        padding:20px 60px;
    }
    .item-g__product3Title > h3 {
        font-size:20px;
    }
    .catproducto--categories .categories-block__title {
        padding: 0 60px;
    }
}
/* Desktop and up */
@media (min-width:1025px) {
    .productd-tabContentItem .item-descarga__list {
        padding-left:0;
        line-height:0.8;
    }
    .productd-tabContentItem .item-descarga__list:first-child {
        padding-top:0;
    }
    .productd-tabContentItem .productd-distribuidores {
        margin-top:0;
    }
    .item-g.item-g__familia,.item-g4__item {
        display:flex;
        flex-direction:column;
        height:100%;
    }
    .item-g__familiaInfo,.item-g4__info {
        flex-grow:1;
    }
    .productdetail-inside {
        align-items:start;
    }
    .productpage {
        height:620px;
    }
    .productpage__infoTitle h2 {
        font-size:56px;
    }
    .productpage__info {
        width:44%;
        padding-bottom:160px;
    }
    .productpage__infoText {
        margin-top:30px;
        font-size:16px;
    }
}
