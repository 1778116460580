.filter-block__itemDescarga {
	margin-bottom:40px;
	width:100%
}
.item-descarga {
	background:#E6E6E6
}
.item-descarga__list {
	padding:25px 30px 10px 30px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
.item-descarga__listWrapIn {
	padding-bottom:40px
}
.item-descarga__listInside {
	border-bottom:1px solid #B3B3B3
}
.item-descarga__listIn {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	-webkit-box-pack:justify;
	-ms-flex-pack:justify;
	justify-content:space-between
}
.item-descarga__listIn {
	width:100%
}
.item-descarga__listInIn {
	padding:20px 0 20px 0;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	border-top:1px solid #B3B3B3;
	width:calc(100% - 85px);
	margin-right:0;
	margin-left:auto;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	-webkit-box-pack:justify;
	-ms-flex-pack:justify;
	justify-content:space-between
}
.item-descarga__title {
	background:#F1F1F1;
	min-height:75px;
	padding:15px 30px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	position:relative
}
.item-descarga__title.active > span:before {
	opacity:0;
	visibility:hidden
}
.item-descarga__title.active > span:after {
	opacity:1;
	visibility:visible
}
.item-descarga__title > h3 {
	font-size:17px;
	font-family:"TTNorms-Bold", sans-serif;
	color:#000;
	margin:0;
	letter-spacing:1.05px
}
.item-descarga__title > span {
	position:absolute;
	top:50%;
	-webkit-transform:translateY(-50%);
	-ms-transform:translateY(-50%);
	transform:translateY(-50%);
	right:30px;
	font-size:30px;
	font-family:"TTNorms-Bold", sans-serif;
	color:#E3051B
}
.item-descarga__title > span:before, .item-descarga__title > span:after {
	position:relative
}
.item-descarga__title > span:before {
	content:"+"
}
.item-descarga__title > span:after {
	content:"-";
	position:absolute;
	left:0;
	opacity:0;
	visibility:hidden
}
.item-descarga__listTitle {
	padding-bottom:25px;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center
}
.item-descarga__listTitle.active > span:before {
	opacity:0;
	visibility:hidden
}
.item-descarga__listTitle.active > span:after {
	opacity:1;
	visibility:visible
}
.item-descarga__listTitle > h4 {
	font-size:17px;
	font-family:"TTNorms-Bold", sans-serif;
	color:#E3051B;
	margin:0;
	letter-spacing:0.4px
}
.item-descarga__listTitle > span {
	margin:0;
	display:block;
	font-size:24px;
	font-family:"TTNorms-Bold", sans-serif;
	color:#E3051B;
	margin-left:10px;
	position:relative
}
.item-descarga__listTitle > span:before, .item-descarga__listTitle > span:after {
	position:relative
}
.item-descarga__listTitle > span:before {
	content:"+"
}
.item-descarga__listTitle > span:after {
	content:"-";
	position:absolute;
	left:0;
	opacity:0;
	visibility:hidden
}
.item-descarga__listInTitle {
	margin-bottom:15px
}
.item-descarga__listInTitle > h5 {
	font-size:17px;
	font-family:"TTNorms-Regular", sans-serif;
	color:#000;
	margin:0
}
.item-descarga__listInContent {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex
}
.item-descarga__listInContent p {
	margin:0;
	margin-right:25px;
	font-size:17px;
	font-family:"TTNorms-Regular", sans-serif;
	color:#3C3C3B
}
.item-descarga__listInContent span {
	margin:0;
	margin-right:25px;
	font-size:17px;
	font-family:"TTNorms-Regular", sans-serif;
	color:#3C3C3B
}
.item-descarga__listInContent a {
	margin:0;
	font-size:17px;
	font-family:"TTNorms-Regular", sans-serif;
	color:#3C3C3B
}
.acordionContent--js2 {
	display:none
}
.acordionContent--js2.active {
	display:block
}
.acordion--js2 {
	cursor:pointer
}
@media (max-width:1024px) {
	.item-descarga__listInIn {
		width:100%
	}
	.item-descarga__listInTitleContent {
		padding-right:15px;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
}
@media (max-width:1024px) {
	.filter--descargas .filter-block__container {
		padding:0 !important
	}
	.filter--descargas .filter-block__filter {
		padding-left:40px;
		padding-right:40px
	}
	.filter--descargas .filter-block__title {
		padding-left:40px;
		padding-right:40px
	}
	.filter--descargas .filter-block__result {
		padding-left:40px;
		padding-right:40px
	}
	.item-descarga__title > h3 {
		font-size:14px
	}
	.item-descarga__listTitle > h4 {
		font-size:16px
	}
	.item-descarga__listInTitle > h5 {
		font-size:14px
	}
	.item-descarga__listInContent {
		-ms-flex-wrap:wrap;
		flex-wrap:wrap
	}
	.item-descarga__listInContent p {
		font-size:14px
	}
	.item-descarga__listInContent span {
		font-size:14px
	}
	.item-descarga__listInContent a {
		font-size:14px
	}
	.item-descarga__listInButton {
		display:-webkit-box;
		display:-ms-flexbox;
		display:flex;
		-webkit-box-align:center;
		-ms-flex-align:center;
		align-items:center
	}
	.item-descarga__listInButton .button-g__text {
		font-size:0 !important;
		width:40px !important;
		height:40px !important;
		line-height:40px !important
	}
}
@media (max-width:480px) {
	.filter--descargas .filter-block__filter {
		padding-left:30px;
		padding-right:30px
	}
	.filter--descargas .filter-block__title {
		padding-left:30px;
		padding-right:30px
	}
	.filter--descargas .filter-block__result {
		padding-left:30px;
		padding-right:30px
	}
}
.filter--averia {
	padding-bottom:75px
}
.filter--averia .filter-block__result {
	padding-bottom:0;
	padding-top:40px
}
.filter--averia .filter-block__title {
	max-width:550px;
	width:100%;
	padding-bottom:75px
}
.filter--averia .filter-block__title .title-g__title h1, .filter--averia .filter-block__title .title-g__title h2, .filter--averia .filter-block__title .title-g__title h3, .filter--averia .filter-block__title .title-g__title h4, .filter--averia .filter-block__title .title-g__title h5 {
	letter-spacing:5.4px;
	line-height:1.2
}
.filter--averia .input-g__lineOne .input-g__lineIn {
	width:49.5%;
	margin-right:1%
}
.filter--averia .input-g__lineOne .input-g__lineIn:last-child {
	margin-right:0
}
.filter--averia .input-g__lineNombreApellido.input-g__lineTwoColumns .input-g {
	width:49%;
	margin-right:2%
}
.filter--averia .input-g__lineNombreApellido.input-g__lineTwoColumns .input-g:last-child {
	margin-right:0
}
.filter--averia .input-g__lineTipoModelo .input-g__lineIn {
	width:49.5%;
	margin-right:1%
}
.filter--averia .input-g__lineTipoModelo .input-g__lineCodigoProd {
	width:49.5% !important
}
.filter--averia .input-g__lineTipoModelo.input-g__lineTwoColumns .input-g {
	width:49%;
	margin-right:2%
}
.filter--averia .input-g__lineTipoModelo.input-g__lineTwoColumns .input-g:last-child {
	margin-right:0
}
.filter--averia .input-g__lineEmailTelefono .input-g__lineIn {
	width:49.5%;
	margin-right:1%
}
.filter--averia .input-g__lineEmailTelefono .input-g__lineDirEmpresa {
	width:49.5%
}
.filter--averia .input-g__lineEmailTelefono .input-g__lineIn1 .input-g {
	width:49%;
	margin-right:2%
}
.filter--averia .input-g__lineEmailTelefono .input-g__lineIn1 .input-g:last-child {
	margin-right:0
}
@media (max-width:1024px) {
	.filter--averia .filter-block__title {
		padding-bottom:50px
	}
	.filter--averia .input-g__lineTwoColumns .input-g__lineIn {
		width:100% !important;
		margin-right:0 !important
	}
	.filter--averia .input-g__lineTipoModelo.input-g__lineTwoColumns .input-g {
		width:49%;
		margin-right:2%
	}
	.filter--averia .input-g__lineTipoModelo.input-g__lineTwoColumns .input-g:last-child {
		margin-right:0
	}
	.filter--averia .input-g__lineTipoModelo .input-g__lineCodigoProd {
		width:100% !important
	}
	.filter--averia .input-g__lineEmailTelefono.input-g__lineTwoColumns .input-g {
		width:49%;
		margin-right:2%
	}
	.filter--averia .input-g__lineEmailTelefono.input-g__lineTwoColumns .input-g:last-child {
		margin-right:0
	}
	.filter--averia .input-g__lineNombreApellido.input-g__lineTwoColumns .input-g {
		width:49%;
		margin-right:2%
	}
	.filter--averia .input-g__lineNombreApellido.input-g__lineTwoColumns .input-g:last-child {
		margin-right:0
	}
	.filter--averia .input-g__lineDirEmpresa {
		width:100% !important
	}
	.filter--averia .input-g__lineCodigoProd {
		width:100% !important
	}
}
@media (max-width:1024px) {
	.filter--averia {
		padding-bottom:40px
	}
	.filter--averia .filter-block__title {
		padding-bottom:30px
	}
	.filter--averia .input-g__lineTipoModelo.input-g__lineTwoColumns .input-g {
		width:100%;
		margin-right:0
	}
	.filter--averia .input-g__lineEmailTelefono.input-g__lineTwoColumns .input-g {
		width:100%;
		margin-right:0
	}
	.filter--averia .input-g__lineNombreApellido.input-g__lineTwoColumns .input-g {
		width:100%;
		margin-right:0
	}
}
.banner-g--soporte .banner-g__inside {
	max-width:1280px
}
.banner-g--soporte .banner-g__image {
	height:780px
}
.banner-g--soporte .banner-g__info {
	width:100%;
	text-align:right
}
.banner-g--soporte .title-g .title-g__title h1, .banner-g--soporte .title-g .title-g__title h2, .banner-g--soporte .title-g .title-g__title h3, .banner-g--soporte .title-g .title-g__title h4, .banner-g--soporte .title-g .title-g__title h5 {
	letter-spacing:5.4px
}
.banner-g--soporte .title-g .title-g__text {
	margin-top:35px;
	line-height:1.5
}
@media (max-width:1024px) {
	.banner-g--soporte .banner-g__image {
		height:610px;
		padding:70px 0;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
	.banner-g--soporte .banner-g__info {
		width:100%;
		text-align:left;
		margin-top:80px;
	}
	.banner-g--soporte .banner-g__info h1{
		font-size: 40px;
	}
	.banner-g--soporte .banner-g__inside {
		-webkit-box-align:start;
		-ms-flex-align:start;
		align-items:flex-start
	}
}
.soporte--zigzag .title-g__title h1, .soporte--zigzag .title-g__title h2, .soporte--zigzag .title-g__title h3, .soporte--zigzag .title-g__title h4 {
	letter-spacing:1.6px
}
.soporte--zigzag .title-g__text {
	line-height:1.5
}
.preguntas-block {
	margin-top:50px
}
.preguntas-block__links {
	background:-webkit-gradient(linear, left top, right top, from(#e2051b), to(#e9590c));
	background:-o-linear-gradient(left, #e2051b 0%, #e9590c 100%);
	background:linear-gradient(to right, #e2051b 0%, #e9590c 100%);
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap;
	-webkit-box-pack:center;
	-ms-flex-pack:center;
	justify-content:center
}
.preguntas-block__link {
	width:33.33%;
	height:260px;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	-webkit-box-pack:center;
	-ms-flex-pack:center;
	justify-content:center;
	border-right:2px solid #fff;
	border-bottom:2px solid #fff;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	position:relative
}
.preguntas-block__link:hover:before {
	opacity:1;
	visibility:visible
}
.preguntas-block__link:before {
	content:"";
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background:rgba(0, 0, 0, 0.2);
	-webkit-transition:all 0.3s ease-in-out;
	-o-transition:all 0.3s ease-in-out;
	transition:all 0.3s ease-in-out;
	opacity:0;
	visibility:hidden;
	z-index:2
}
.preguntas-block__link:nth-child(3n+3) {
	border-right:none
}
.preguntas-block__linkInside {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	position:relative;
	z-index:5
}
.preguntas-block__linkImage {
	width:85px;
	margin:0;
	margin-right:20px
}
.preguntas-block__linkImage img {
	max-width:100%;
	height:auto;
	display:block
}
.preguntas-block__linkTitle > h2 {
	letter-spacing:0.19px;
	font-size:21px;
	color:#fff;
	font-family:"TTNorms-Bold", sans-serif;
	margin:0;
	line-height:1.2
}
.preguntas-block__content {
	padding-top:70px
}
.preguntas-block__contentItem {
	margin-bottom:60px
}
.preguntas-block__contentItem:last-child {
	margin-bottom:0
}
.preguntas-block__contentItemTitle {
	margin-bottom:50px;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center
}
.preguntas-block__contentItemTitle > .preguntas-block__contentItemImg {
	width:85px;
	margin:0;
	margin-right:20px
}
.preguntas-block__contentItemTitle > .preguntas-block__contentItemImg img {
	max-width:100%;
	height:auto;
	display:block
}
.preguntas-block__contentItemTitle > h2 {
	letter-spacing:0.19px;
	font-size:21px;
	color:#E3051B;
	font-family:"TTNorms-Bold", sans-serif;
	margin:0;
	line-height:1.2
}
.preguntas-block__contentItemIn.active {
	background:#F1F1F1
}
.preguntas-block__contentItemInTitle {
	min-height:75px;
	padding:15px 70px 15px 30px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	position:relative;
	border-bottom:1px solid gray
}
.preguntas-block__contentItemInTitle.active {
	border-top:1px solid gray;
	border-bottom:none;
	margin-top:-1px
}
.preguntas-block__contentItemInTitle.active > span:before {
	opacity:0;
	visibility:hidden
}
.preguntas-block__contentItemInTitle.active > span:after {
	opacity:1;
	visibility:visible
}
.preguntas-block__contentItemInTitle > h3 {
	font-size:20px;
	font-family:"TTNorms-Bold", sans-serif;
	color:#000;
	margin:0;
	line-height:1.2
}
.preguntas-block__contentItemInTitle > span {
	position:absolute;
	top:50%;
	-webkit-transform:translateY(-50%);
	-ms-transform:translateY(-50%);
	transform:translateY(-50%);
	right:30px;
	font-size:30px;
	font-family:"TTNorms-Bold", sans-serif;
	color:#E3051B
}
.preguntas-block__contentItemInTitle > span:before, .preguntas-block__contentItemInTitle > span:after {
	position:relative
}
.preguntas-block__contentItemInTitle > span:before {
	content:"+"
}
.preguntas-block__contentItemInTitle > span:after {
	content:"-";
	position:absolute;
	left:0;
	opacity:0;
	visibility:hidden
}
.preguntas-block__contentItemInContentIn {
	padding:10px 30px 30px 30px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	border-bottom:1px solid gray;
	font-size:20px;
	font-family:"TTNorms-Regular", sans-serif;
	color:#000
}
.acordion--js {
	cursor:pointer
}
@media (max-width:1024px) {
	.preguntas-block__link {
		width:50%;
		height:150px
	}
	.preguntas-block__link:nth-child(3n+3) {
		border-right:2px solid #fff !important
	}
	.preguntas-block__link:nth-child(2n+2) {
		border-right:none !important
	}
	.preguntas-block__linkTitle > h2 {
		font-size:17px
	}
	.preguntas-block__contentItemInTitle > h3 {
		font-size:17px
	}
}
@media (max-width:1024px) {
	.preguntas-block__link {
		width:100%;
		height:90px;
		border:none !important;
		border-bottom:1px solid #fff !important
	}
	.preguntas-block__link:nth-child(3n+3) {
		border-right:none !important
	}
	.preguntas-block__linkTitle > h2 {
		font-size:14px
	}
	.preguntas-block__contentItemInTitle > h3 {
		font-size:14px
	}
	.preguntas-block__contentItemInTitle {
		padding:15px 70px 15px 40px
	}
	.preguntas-block__contentItemInContentIn {
		font-size:14px;
		padding:0 40px 20px 40px
	}
	.preguntas-block__container {
		padding:0 !important
	}
	.preguntas-block__contentItemTitle {
		padding:0 40px;
		-webkit-box-sizing:border-box;
		box-sizing:border-box;
		margin-bottom:15px
	}
	.preguntas-block__linkImage {
		width:60px;
		margin-right:15px
	}
	.preguntas-block__contentItemTitle > .preguntas-block__contentItemImg {
		width:60px;
		margin-right:15px
	}
	.filter--preguntas {
		padding-top:40px
	}
	.preguntas-block {
		margin-top:30px
	}
}
.banner-g--averia .banner-g__image {
	height:750px
}
.banner-g--averia .banner-g__info {
	max-width:550px;
	width:100%;
	margin-right:auto;
	margin-left:0
}
.banner-g--averia .banner-g__info .title-g__title h1, .banner-g--averia .banner-g__info .title-g__title h2, .banner-g--averia .banner-g__info .title-g__title h3, .banner-g--averia .banner-g__info .title-g__title h4, .banner-g--averia .banner-g__info .title-g__title h5 {
	letter-spacing:1.63px;
	line-height:1.2
}
.banner-g--averia .banner-g__info .title-g__text {
	margin-top:45px
}
.banner-g--averia .banner-g__info .title-g__buttons {
	margin-top:40px
}
.banner-g--averia .banner-g__inside {
	max-width:1680px
}
@media (max-width:1024px) {
	.banner-g--averia .banner-g__image {
		height:600px
	}
	.banner-g--averia .banner-g__inside {
		-webkit-box-align:end;
		-ms-flex-align:end;
		align-items:flex-end;
		padding-bottom:45px;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
	.banner-g--averia .banner-g__info {
		max-width:300px;
		margin:0 auto
	}
	.banner-g--averia .banner-g__container {
		padding:0 !important
	}
}

.block-soporteb__container {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex
}
.block-soporteb__item {
	width:50%;
	padding:40px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	height:730px;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center
}
.block-soporteb__b1 {
	background:#F1F1F1
}
.block-soporteb__b1 .block-soporteb__titleb1 {
	max-width:720px;
	width:100%;
	margin:auto
}
.block-soporteb__b1 .block-soporteb__titleb1 .title-g__icon {
	width:88px;
	height:80px;
	margin-bottom:25px
}
.block-soporteb__b1 .block-soporteb__titleb1 .title-g__icon img {
	max-width:100%;
	height:auto;
	display:block
}
.block-soporteb__b1 .block-soporteb__titleb1 .title-g__text {
	margin-top:40px;
	line-height:1.3
}
.block-soporteb__b1 .block-soporteb__titleb1 .title-g__buttons {
	margin-top:45px
}
.block-soporteb__b2 {
	background:#E4E4E4
}
.block-soporteb__b2 .block-soporteb__titleb2 {
	max-width:680px;
	width:100%;
	margin:auto
}
.block-soporteb__b2 .block-soporteb__titleb2 .title-g__icon {
	width:88px;
	height:80px;
	margin-bottom:25px
}
.block-soporteb__b2 .block-soporteb__titleb2 .title-g__icon img {
	max-width:100%;
	height:auto;
	display:block
}
.block-soporteb__b2 .block-soporteb__titleb2 .title-g__text {
	margin-top:40px;
	line-height:1.3
}
.block-soporteb__b2 .block-soporteb__titleb2 .title-g__buttons {
	margin-top:45px
}
@media (max-width:1200px) {
	.block-soporteb__formbtns {
		-ms-flex-wrap:wrap;
		flex-wrap:wrap;
		-webkit-box-pack:center;
		-ms-flex-pack:center;
		justify-content:center
	}
	.block-soporteb__formbtn1, .block-soporteb__formbtn2 {
		margin:0 10px
	}
	.block-soporteb__formbtn1 {
		margin-bottom:20px
	}
}
@media (max-width:1024px) {
	.block-soporteb__container {
		-ms-flex-wrap:wrap;
		flex-wrap:wrap
	}
	.block-soporteb__item {
		width:100%;
		height:auto;
		padding:45px 40px
	}
	.block-soporteb__b1 .block-soporteb__titleb1 .title-g__icon {
		width:54px;
		height:54px;
		margin-bottom:15px
	}
	.block-soporteb__b2 .block-soporteb__titleb2 .title-g__icon {
		width:54px;
		height:54px;
		margin-bottom:15px
	}
	.block-soporteb__b1 .block-soporteb__titleb1 .title-g__text {
		margin-top:20px
	}
	.block-soporteb__b1 .block-soporteb__titleb1 .title-g__buttons {
		margin-top:30px
	}
	.block-soporteb__b2 .block-soporteb__titleb2 .title-g__text {
		margin-top:20px
	}
	.block-soporteb__b2 .block-soporteb__titleb2 .title-g__buttons {
		margin-top:30px
	}
	.block-soporteb__b2 .block-soporteb__titleb2 .title-g__icon {
		width:54px;
		height:54px
	}
}
@media (max-width:400px) {
	.block-soporteb__item {
		padding:45px 30px
	}
}
.portalgp {
	padding-top:30px;
	padding-bottom:100px;
	background:-webkit-gradient(linear, left top, right top, from(#e9590c), to(#e2051b));
	background:-o-linear-gradient(left, #e9590c 0%, #e2051b 100%);
	background:linear-gradient(to right, #e9590c 0%, #e2051b 100%)
}
.portalgp-menu .miga-g > ul {
	-webkit-box-pack:end;
	-ms-flex-pack:end;
	justify-content:flex-end
}
.portalgp-menu .miga-g > ul > li:last-child > a {
	color:#fff !important
}
.portalgp-menu .miga-g > ul > li:last-child > a:hover {
	color:#fff !important
}
.portalgp-menu .miga-g > ul > li > a {
	color:#000 !important
}
.portalgp-menu .miga-g > ul > li > a:hover {
	color:#000 !important
}
.portalgp-block1Menu {
	margin-bottom:15px
}
.portalgp-block1Menu > ul {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex
}
.portalgp-block1Menu > ul > li {
	position:relative;
	color:#fff;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center
}
.portalgp-block1Menu > ul > li:last-child:after {
	display:none
}
.portalgp-block1Menu > ul > li:after {
	content:"|";
	margin:0 15px;
	display:inline-block;
	vertical-align:middle
}
.portalgp-block1Menu > ul > li > a {
	font-size:19px;
	letter-spacing:0.93px;
	color:#fff;
	font-family:"TTNorms-Light", sans-serif;
	text-transform:uppercase
}
.portalgp-block1Menu > ul > li.current_page_item > a {
	font-size:24px;
	letter-spacing:1.18px;
	font-family:"TTNorms-Medium", sans-serif
}
.portalgp-block1 {
	width:55%
}
@media (max-width:767px) {
	.portalgp-block1Menu > ul > li > a {
		font-size:14px
	}
	.portalgp-block1Menu > ul > li.current_page_item > a {
		font-size:18px
	}
	.portalgp-block1 {
		width:65%
	}
	.portalgp {
		padding-top:30px;
		padding-bottom:50px
	}
}
@media (max-width:767px) {
	.portalgp-block1 {
		width:100%;
		-webkit-box-ordinal-group:3;
		-ms-flex-order:2;
		order:2
	}
	.portalgp-menu {
		display:none
	}
	.portalgp-blocks {
		margin-top:0
	}
	.portalgp-block1Menu {
		display:none
	}
	.filter-block__title {
		margin-top:0
	}
	.portalgp {
		padding-top:35px
	}
	.portalgp-container {
		padding:0 !important
	}
	.portalgp {
		padding-top:30px;
		padding-bottom:40px
	}
}
.portalgp-rcompany {
	background:#fff;
	padding:40px 50px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	margin-top:80px;
	border-radius:20px;
	overflow:hidden;
	-webkit-box-shadow:-10px 10px 20px rgba(0, 0, 0, 0.2);
	box-shadow:-10px 10px 20px rgba(0, 0, 0, 0.2)
}
.portalgp-rcompanyTitle {
	margin-bottom:40px
}
.portalgp-rcompanyTitle > h1 {
	font-size:18px;
	font-family:"TTNorms-Bold", sans-serif;
	color:#000;
	margin:0;
	text-transform:uppercase
}
.portalgpEmpresa .portalgp-container {
	max-width:1500px
}
.portalgpEmpresa .form-block__checkboxRadio {
	margin-bottom:20px
}
@media (max-width:767px) {
	.portalgpEmpresa {
		padding:0
	}
	.portalgp-rcompany {
		margin-top:0;
		padding:40px;
		border-radius:0;
		-webkit-box-shadow:none;
		box-shadow:none
	}
	.portalgp-rcompanyTitle > h1 {
		font-size:14px
	}
}
.portalgp-rplataforma {
	background:#fff;
	padding:40px 50px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	border-radius:20px;
	overflow:hidden;
	-webkit-box-shadow:-10px 10px 20px rgba(0, 0, 0, 0.2);
	box-shadow:-10px 10px 20px rgba(0, 0, 0, 0.2)
}
.portalgp-rplataformaTitleUser {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:end;
	-ms-flex-align:end;
	align-items:flex-end;
	-webkit-box-pack:justify;
	-ms-flex-pack:justify;
	justify-content:space-between;
	margin-bottom:50px
}
.portalgp-rplataformaTitle {
	width:calc(100% - 80px)
}
.portalgp-rplataformaTitle > h2 {
	font-size:29px;
	line-height:1.4;
	letter-spacing:1.42px;
	color:#1D1D1B;
	font-family:"TTNorms-Bold", sans-serif;
	margin:0
}
.portalgp-rplataformaUser {
	margin:0;
	text-align:center;
	width:80px
}
.portalgp-rplataformaUser > span {
	display:block;
	background:url("../img/icons/micuentaicon.png");
	width:31px;
	height:31px;
	background-size:100%;
	background-position:0 0;
	background-repeat:no-repeat
}
.portalgp-rplataformaUser > h3 {
	font-size:15px;
	color:#000;
	line-height:1.2;
	margin:0;
	margin-top:5px;
	-webkit-transition:all 0.3s ease-in-out;
	-o-transition:all 0.3s ease-in-out;
	transition:all 0.3s ease-in-out
}
.portalgp-rplataformaUser:hover > h3 {
	font-weight:bold
}
.portalgp-rplataformaItem {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	-webkit-box-pack:justify;
	-ms-flex-pack:justify;
	justify-content:space-between;
	padding:40px 55px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	border-bottom:1px solid #E4E4E4
}
.portalgp-rplataformaItem:last-child {
	border-bottom:none;
	padding:80px 40px
}
.portalgp-rplataformaItemIn {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	width:calc(100% - 300px)
}
.portalgp-rplataformaItemImage {
	width:145px
}
.portalgp-rplataformaItemImage img {
	max-width:100%;
	height:auto;
	display:block;
	margin:auto
}
.portalgp-rplataformaItemButton {
	margin:0;
	width:300px
}
.portalgp-rplataformaItemText {
	padding-left:80px;
	padding-right:20px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	width:calc(100% - 145px)
}
.portalgp-rplataformaItemText > h3 {
	font-size:18px;
	letter-spacing:0.88px;
	line-height:1.3;
	color:#E3051B;
	font-family:"TTNorms-Bold", sans-serif;
	text-transform:uppercase
}
.portalgp-rplataformaItemText > section {
	font-size:17px;
	line-height:1.5;
	color:#1D1D1B;
	font-family:"TTNorms-Light", sans-serif
}
.portalgp-rplataformaLogout {
	text-align:right
}
.portalgp-rplataformaLogout > a {
	position:relative;
	color:#878787;
	font-family:"TTNorms-Light", sans-serif;
	font-size:15px
}
.portalgp-rplataformaLogout > a:after {
	content:">";
	margin-left:3px
}
.portalgp-rplataformaLogout > a:hover {
	font-weight:bold
}
@media (max-width:767px) {
	.portalgp-rplataformaItem {
		padding:40px 30px
	}
	.portalgp-rplataformaItemImage {
		width:100px
	}
	.portalgp-rplataformaItemText {
		width:calc(100% - 100px);
		padding-left:50px
	}
	.portalgp-rplataformaItemButton {
		width:auto
	}
	.portalgp-rplataformaTitle > h2 {
		font-size:20px
	}
}
@media (max-width:960px) {
	.portalgp-rplataformaItemText {
		display:none
	}
}
@media (max-width:767px) {
	.portalgp-rplataforma {
		border-radius:0;
		margin-top:0;
		-webkit-box-shadow:none;
		box-shadow:none
	}
	.portalgp-rplataformaUser > h3 {
		display:none
	}
	.portalgp-rplataformaTitle > h2 {
		font-size:14px
	}
	.portalgp-rplataformaItemImage {
		width:70px;
		margin:auto;
		margin-bottom:17px
	}
	.portalgp-rplataformaItemIn {
		width:100%
	}
	.portalgp-rplataformaItemButton {
		width:100%
	}
	.portalgp-rplataformaItem {
		-ms-flex-wrap:wrap;
		flex-wrap:wrap;
		text-align:center
	}
	.portalgp-rplataformaItem {
		border:none;
		padding:40px 0
	}
	.portalgp-rplataformaItem:last-child {
		padding-top:40px;
		padding-bottom:40px
	}
	.portalgp-rplataformaTitleUser {
		-webkit-box-align:start;
		-ms-flex-align:start;
		align-items:flex-start
	}
	.portalgp-rplataformaUser {
		width:32px
	}
	.portalgp-rplataformaTitle {
		width:calc(100% - 32px);
		padding-right:15px;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
	.portalgp-rplataformaTitleUser {
		margin-bottom:20px
	}
	.portalgp-rplataformaLogout > a {
		font-size:14px
	}
}
/*  Check Warranty  */
.table__g h3 {
	padding:40px 0;
	font-size:20px;
	font-family:"TTNorms-Regular", sans-serif;
	color:#000
}
.table__g table {
	width:100%
}
.table__g thead th {
	background:#fff !important;
	text-align:center;
	font-size:18px;
	font-family:"TTNorms-Regular", sans-serif;
	color:#8B8B8A;
	border-top:1px solid #E4E4E4;
	border-bottom:1px solid #E4E4E4;
	padding:15px 10px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	cursor:pointer;
	position:relative
}
.table__g thead th:after {
	content:"";
	display:inline-block;
	vertical-align:middle;
	background:url(../img/icons/arrow-bottom.svg);
	width:13px;
	height:8px;
	background-size:100%;
	background-position:0 0;
	background-repeat:no-repeat;
	margin-right:9px
}
.table__g tbody {
	max-height:381px;
	overflow-y:auto
}
.table__g tbody td {
	border-bottom:1px solid #E4E4E4;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	text-align:center;
	padding:0 !important
}
.table__g tbody td p {
	display:table-cell;
	vertical-align:middle;
	padding:7px 10px;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	font-size:18px;
	line-height:1.2;
	color:#1D1D1B;
	letter-spacing:0.9px;
	width:2000px;
	text-align:center
}
.table__g--two--columns thead th {
	width:50%
}
.table__g--two--columns tbody td {
	width:50%
}
.table__g--two--columns tfoot td {
	width:50%
}
.table__g--three--columns thead th {
	width:33.33%
}
.table__g--three--columns tbody td {
	width:33.33%
}
.table__g--three--columns tfoot td {
	width:33.33%
}
.table__g--four--columns thead th {
	width:25%
}
.table__g--four--columns tbody td {
	width:25%
}
.table__g--four--columns tfoot td {
	width:25%
}
.table__g--five--columns thead th {
	width:20%
}
.table__g--five--columns tbody td {
	width:20%
}
.table__g--five--columns tfoot td {
	width:20%
}
.table__g--six--columns thead th {
	width:16.666%
}
.table__g--six--columns tbody td {
	width:16.666%
}
.table__g--six--columns tfoot td {
	width:16.666%
}
.table__g--seven--columns thead th {
	width:14.285%
}
.table__g--seven--columns tbody td {
	width:14.285%
}
.table__g--seven--columns tfoot td {
	width:14.285%
}
.portalgp-rplataformaLogoutBack {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	-webkit-box-pack:justify;
	-ms-flex-pack:justify;
	justify-content:space-between
}
.portalgp-rplataformaGarantiaSearch {
	padding-top:30px;
	border-top:1px solid #E4E4E4
}
.portalgp-rplataformaGarantiaSearch form {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	margin:0 !important;
	padding-bottom:30px;
	border-bottom:1px solid #E4E4E4
}
.portalgp-rplataformaGarantiaSearch form > ul {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center
}
.portalgp-rplataformaGarantiaSearch .button-g__text {
	height:40px !important;
	line-height:40px !important
}
.portalgp-rplataformaGarantiaSearch1 {
	width:320px;
	margin-right:25px
}
.portalgp-rplataformaGarantiaSearch2 {
	width:200px;
	margin-right:30px
}
.portalgpGarantia .portalgp-block1Menu {
	margin-bottom:30px
}
.portalgpGarantia .portalgp-menu {
	margin-bottom:20px
}
.portalgpGarantia .portalgp-blockAll {
	max-width:1400px;
	margin:auto;
	width:100%
}
.portalgpGarantia .portalgp-rplataformaTitleUser {
	margin-bottom:20px;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center
}
.portalgplinks .portalgp-menu {
	margin-bottom:80px
}
.portalgplinks .portalgp-container {
	max-width:1500px
}
@media (max-width:767px) {
	.portalgp-rplataformaGarantiaSearch1 {
		width:230px
	}
	.portalgplinks .portalgp-menu {
		margin-bottom:40px
	}
}
@media screen and (max-width:1024px) {
	.portalgpGarantia {
		padding-top:0;
		padding-bottom:0
	}
	.portalgplinks {
		padding-top:0;
		padding-bottom:0
	}
	.table__g--responsive thead {
		display:none
	}
	.table__g--responsive tbody tr {
		display:block;
		width:100%;
		margin-top:20px
	}
	.table__g--responsive tbody td {
		display:block;
		width:100%;
		border:none;
		font-size:14px;
		color:#1D1D1B;
		font-family:"TTNorms-Regular", sans-serif;
		letter-spacing:0.7px
	}
	.table__g--responsive tbody td:first-child {
		border-top:1px solid #E4E4E4;
		padding-top:20px !important
	}
	.table__g--responsive tfoot tr {
		display:block;
		width:100%;
		margin-top:0
	}
	.table__g--responsive tfoot td {
		display:block;
		width:100%;
		border:none;
		font-size:14px;
		color:#1D1D1B;
		font-family:"TTNorms-Regular", sans-serif
	}
	.table__g--responsive tfoot tr .table__g__responsive__cell {
		border-top:none !important
	}
	.table__g--responsive .table__g__responsive__cell:before {
		content:attr(data-text);
		position:relative;
		top:0;
		left:0;
		width:50%;
		display:table-cell;
		vertical-align:middle;
		vertical-align:middle;
		color:gray;
		font-family:"TTNorms-Regular", sans-serif;
		font-size:10px;
		padding:5px;
		-webkit-box-sizing:border-box;
		box-sizing:border-box
	}
	.table__g--responsive .table__g__responsive__cell:before {
		font-size:10px
	}
	.table__g tbody td {
		padding:0 !important
	}
	.portalgp-rplataformaGarantiaSearch1 {
		width:100%;
		margin-right:0;
		margin-bottom:10px
	}
	.portalgp-rplataformaGarantiaSearch2 {
		width:100%;
		margin-right:0;
		margin-bottom:10px
	}
	.portalgp-rplataformaGarantiaSearch form > ul {
		-ms-flex-wrap:wrap;
		flex-wrap:wrap;
		width:100%
	}
	.portalgp-rplataformaGarantiaSearch form {
		-ms-flex-wrap:wrap;
		flex-wrap:wrap
	}
	.table__g tbody td p {
		font-size:14px;
		text-align:left
	}
	.table__g tbody td {
		text-align:left
	}
	.portalgp-rplataformaGarantiaSearch form {
		border:none;
		padding-bottom:20px
	}
	.portalgp-rplataforma {
		padding:40px 30px
	}
}
.portalgp-rProductosItem > ul > li {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center
}
.portalgp-rProductosNum {
	width:30px;
	font-family:"TTNorms-Bold", sans-serif;
	color:#E3051B;
	font-size:18px;
	letter-spacing:0.88px;
	margin:0;
	margin-bottom:12px
}
.portalgp-rProductosClose {
	width:50px;
	text-align:center;
	font-family:"TTNorms-Bold", sans-serif;
	color:#b4b4b4;
	font-size:18px;
	margin:0;
	margin-bottom:12px
}
.portalgp-rProductosCod {
	width:40%;
	margin-right:1%;
	margin-bottom:12px
}
.portalgp-rProductosModelo {
	width:20%;
	margin-right:1%;
	margin-bottom:12px
}
.portalgp-rProductosPais {
	width:20%;
	margin-right:1%;
	margin-bottom:12px
}
.portalgp-rProductosFecha {
	width:20%;
	margin-bottom:12px
}
.portalgp-rProductosMore {
	padding:0 !important;
	font-size:18px !important;
	line-height:30px !important;
	color:#8B8B8A !important;
	margin-top:25px !important;
	border:none !important;
	-webkit-appearance:none !important;
	-moz-appearance:none !important;
	appearance:none !important;
	background:none !important
}
.portalgpRproductos .portalgp-rplataformaLogoutBack {
	margin-top:30px
}
.portalgpRproductos .portalgp-rplataformaTitleUser {
	margin-bottom:30px
}
.portalgpRproductos .portalgp-container {
	max-width:1500px
}
.portalgp-rProductosTitle {
	margin-bottom:25px
}
.portalgp-rProductosTitle > h3 {
	font-size:18px;
	letter-spacing:0.88px;
	color:#1D1D1B;
	font-family:"TTNorms-Bold", sans-serif;
	text-transform:uppercase
}
@media screen and (max-width:1024px) {
	.portalgp-rProductosItem > ul > li {
		-ms-flex-wrap:wrap;
		flex-wrap:wrap;
		position:relative;
		padding:0 40px 0 20px
	}
	.portalgp-rProductosCod {
		width:100%;
		margin-right:0
	}
	.portalgp-rProductosModelo {
		width:100%;
		margin-right:0
	}
	.portalgp-rProductosPais {
		width:100%;
		margin-right:0
	}
	.portalgp-rProductosFecha {
		width:100%;
		margin-right:0
	}
	.portalgp-rProductosNum {
		position:absolute;
		top:0;
		left:0
	}
	.portalgp-rProductosClose {
		position:absolute;
		top:0;
		right:-10px
	}
	.portalgpRproductos .portalgp-rProductosTitle > h3 {
		font-size:12px
	}
	.portalgpRproductos {
		padding:0
	}
	.portalgp-rProductosMore {
		font-size:14px !important
	}
}
.portalgp.company .portalgp-block1Menu {
	margin-bottom:30px
}
.portalgp-rProductosMore {
	cursor:pointer
}
.portalgp-rplataformaGarantialist tbody tr {
	display:none
}
.portalgp-rplataformaGarantialist tbody tr.active {
	display:table-row
}
/*  Fixes */
.portalgp-rplataformaGarantialist table {
    border-collapse: initial;
}
.portalgp-rplataformaGarantialist .table__g thead th {
    border-collapse: unset;
}
.portalgp-rplataformaGarantialist .table__g thead th {
    border-bottom: 2px solid #E4E4E4;
}
.portalgp-rplataformaGarantialist .table__g thead th:not(:first-child) {
    border-left: 2px solid #E4E4E4;
}
.table__g thead th {
    color:#000;
    font-size:13px;
    font-weight:initial;
}
.table__g thead th:not(:first-child) {
    border-left:2px solid #E4E4E4;
}
.table__g thead th:hover {
    background:#F4F4F4 !important;
}
.portalgp-blockInfoLogin .button-g__text {
    padding:0 20px !important;
}
.table__g tbody td p {
    font-size:13px;
    font-family:"TTNorms-Regular";
}
.table__g thead th {
    font-size:13px;
    padding:4px 0;
}
.table__g thead th:not(:first-child) {
    border-left:1px solid #E4E4E4;
}
.table__g thead th::after {
    width:10px;
    height:6px;
    margin-left:5px;
}
.table__g thead th.ascending::after {
    background:url(../img/icons/arrow-top.svg);
}
.center--zigzag .title-g--border::before {
    background:none;
}
.zigzap-g--gray {
    background:#f2f2f2;
}
#warning-popup .popUp-g__popUpContent {
    max-width:initial;
    line-height:1.4;
    text-align:justify;
}
#warning-popup .popUp-g__popUp {
    max-width:750px;
}
.portalgpGarantia .portalgp-rplataformaLogoutBack {
    margin-top:30px;
}
@media (max-width:767px) {
    .soporte--zigzag .title-g__button {
        text-align:center;
    }
    .soporte--zigzag .zigzap-g__item {
        flex-direction:column-reverse;
    }
    .soporte--garantia .banner-g__container {
        height:700px;
    }
    .spporte--averia {
        text-align:center;
    }
    .spporte--averia .banner-g__container, .banner-g--averia .banner-g__image {
        height:595px;
    }
}
@media (min-width:768px) and (max-width:1024px) {
    .portalgpGarantia .portalgp-block1Menu {
        margin-bottom: 15px;
    }
}
